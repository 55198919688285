import { configureStore, ThunkAction, Action, createAsyncThunk } from '@reduxjs/toolkit';
import { combineReducers } from '@reduxjs/toolkit';

import AccountsReducer from './Accounts.slice';
import UserDetailsReducer from './User.slice';
import OrderUpdateDataReducer from './OrderUpdateData.slice';

const reducer = combineReducers({
  acounts: AccountsReducer,
  userDetails: UserDetailsReducer,
  orderUpdateData: OrderUpdateDataReducer,
});



export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // No need for checking serializable actions anymore
    }),
});

export default store;
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
