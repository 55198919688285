import axios from 'axios';
import { AccountInfo } from 'types/AccountsSlice';
import { IRegisterUserPayload } from 'types/UserSlice';
import { BASE_URL } from './apiConstants';
import {
  ILogin,
  IResetPassword,
} from './requestTypes';
import { client } from 'utils/client';
import { encryptWithAES } from '../utils/AEShelper';




export const logoutService = () => {
  return client.post('/users/logout')
}
export const loginService = (data: ILogin) => {
  return client.post('/users/login', data)
  // return axios({
  //   method: 'post',
  //   url: BASE_URL + '/users/login',
  //   headers: {
  //     'Content-Type': 'application/json',
  //   },
  //   data,
  // });
};

export const resetPasswordService = async (data: IResetPassword) => {
  let encryptedData = await encryptWithAES(JSON.stringify(data))
  return client.put('/users/change-password', { encryptedData: encryptedData })
  // return axios({
  //   method: 'put',
  //   url: BASE_URL + '/users/change-password',
  //   headers: {
  //     'Content-Type': 'application/json',
  //   },
  //   data,
  // });
};
export const fetchUserExistanceService = (
  token: string,
  data: { email: string }
) => {
  return client.post('/users/', data)
  // return axios({
  //   method: 'post',
  //   url: BASE_URL + '/users/',
  //   headers: {
  //     'Content-Type': 'application/json',
  //     Authorization: token,
  //   },
  //   data,
  // });
};

export const forgotPasswordService = (data: { email: string }) => {
  return client.put('/users/forgot-password', data)
  // return axios({
  //   method: 'put',
  //   url: BASE_URL + '/users/forgot-password',
  //   headers: {
  //     'Content-Type': 'application/json',
  //   },
  //   data,
  // });
};

export const updateUserService = (
  data: AccountInfo,
  id: string | number,
  token: string
) => {
  return client.put(`/users/update/${id}`)
  // return axios({
  //   method: 'put',
  //   url: BASE_URL + `/users/update/${id}`,
  //   headers: {
  //     'Content-Type': 'application/json',
  //     Authorization: token,
  //   },
  //   data,
  // });
};

export const deleteUserService = (id: string | number, token: string) => {
  return client.put(`/users/remove/${id}`)
  // return axios({
  //   method: 'put',
  //   url: BASE_URL + `/users/remove/${id}`,
  //   headers: {
  //     'Content-Type': 'application/json',
  //     Authorization: token,
  //   },
  // });
};
export const registerUserService = (data: IRegisterUserPayload) => {
  return client.post('/users/register', data)
  // return axios({
  //   method: 'post',
  //   url: BASE_URL + '/users/register',
  //   headers: {
  //     'Content-Type': 'application/json',
  //   },
  //   data,
  // });
};

export const getMe = () => {
  return client.get('/users/me')
}
export const getUsers = (accessToken: string, params: any) => {
  return client.get('/users', { params })
  // return axios({
  //   method: 'get',
  //   url: BASE_URL + '/users',
  //   headers: {
  //     'Content-Type': 'application/json',
  //     'Authorization': accessToken
  //   },
  //   params: params
  // });
};

export const sendInviteEmailService = (data: { userId?: number | string, parentId?: number | string }, token: string) => {
  return client.post('/users/send-acc-conf-email', data)
  // return axios({
  //   method: 'post',
  //   url: BASE_URL + '/users/send-acc-conf-email',
  //   headers: {
  //     'Content-Type': 'application/json',
  //     Authorization: token,
  //   },
  //   data,
  // });
};

