import React, { ChangeEvent, useEffect, useState } from "react";

import { useAppSelector } from "store/hooks";

import {
  assignContactSalesCustomerService,
  fetchContactListService,
  fetchParentAccountDetailsService,
} from "api/parentService";
import { getUserDetails } from "store/User.slice";
import { IContactDetails, ICustomerDetails } from "api/requestTypes";
import { fetchCustomerContactDetailsService } from "api/customerService";
import SpinnerLoader from "ui/loader/SpinnerLoader";
import { USER_ROLE } from "utils/constants";

const initialInfo: ICustomerDetails = {
  id: "",
  category: "",
  //location: '',
  // fullName: '',
  // nickName: '',
  // text: '',
  // abbreviation: '',
  //tags: '',
  locationId: "",
  royaltyRate: 0,
  //templateId:'',
  logoUrls: "",
  parentId: "",
  createdAt: "",
  updatedAt: "",
  deletedAt: "",
  childData: [],
  //establishedDate:''
  customerAccountName: "",
};
interface AssignProps {
  initialCustomInfo: ICustomerDetails;
  onCancel: Function;
  getUpdatedList: Function;
  onCancelUpdate: Function;
}

const Assign: React.FC<AssignProps> = ({
  initialCustomInfo,
  onCancel,
  onCancelUpdate,
  getUpdatedList,
}) => {
  const { accessToken, role } = useAppSelector(getUserDetails);

  const [submit, setSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [customInfo, setCustomInfo] =
    useState<ICustomerDetails>(initialCustomInfo);
  const [myCPset, setMyCpSet] = useState<Set<string | number>>(new Set());
  const [mySPset, setMySpSet] = useState<Set<string | number>>(new Set());
  const [contactPersonsSelected, setContactPersonsSelected] = useState<
    IContactDetails[]
  >([]);
  const [contactPersons, setContactPersons] = useState<IContactDetails[]>([]);

  const [salesPersonsSelected, setSalesPersonSelected] = useState<
    IContactDetails[]
  >([]);
  const [salesPersons, setSalesPersons] = useState<IContactDetails[]>([]);

  const removeContact = (id: string | number) => {
    const removed = contactPersonsSelected.filter((obj) => {
      return obj.id == id;
    });
    const result = contactPersonsSelected.filter((obj) => {
      return obj.id != id;
    });
    setContactPersonsSelected(result);

    setContactPersons([...contactPersons, ...removed]);
  };

  const handleChangeContact = (e: ChangeEvent<HTMLSelectElement>) => {
    const res = contactPersons.filter((obj) => {
      return obj.id == e.target.value;
    });
    setContactPersonsSelected([...contactPersonsSelected, ...res]);

    const result = contactPersons.filter((obj) => {
      return obj.id != e.target.value;
    });

    setContactPersons(result);
  };

  const removeSales = (id: string | number) => {
    const removed = salesPersonsSelected.filter((obj) => {
      return obj.id == id;
    });
    const result = salesPersonsSelected.filter((obj) => {
      return obj.id != id;
    });
    setSalesPersonSelected(result);
    setSalesPersons([...salesPersons, ...removed]);
  };
  const handleChangeSales = (e: ChangeEvent<HTMLSelectElement>) => {
    const res = salesPersons.filter((obj) => {
      return obj.id == e.target.value;
    });
    setSalesPersonSelected([...salesPersonsSelected, ...res]);
    const result = salesPersons.filter((obj) => {
      return obj.id != e.target.value;
    });
    setSalesPersons(result);
  };
  const save = () => {
    setSubmit(true);
  };

  useEffect(() => {
    const assignContactSales = async () => {
      //mycpset,myspset

      let newlyAssignedContactPersons = contactPersonsSelected.filter(
        (cp) => !myCPset.has(cp.id)
      );
      let newlyAssignedSalesPersons = salesPersonsSelected.filter(
        (cp) => !mySPset.has(cp.id)
      );

      let selectedContactPersonsIdSet: Set<string | number> = new Set(
        contactPersonsSelected.map((c) => c.id)
      );
      let selectedSalesPersonsIdSet: Set<string | number> = new Set(
        salesPersonsSelected.map((s) => s.id)
      );

      let removedContactPersonsIds: (string | number)[] = Array.from(
        myCPset
      ).filter((id) => !selectedContactPersonsIdSet.has(id));
      let removedSalesPersonsIds: (string | number)[] = Array.from(
        mySPset
      ).filter((id) => !selectedSalesPersonsIdSet.has(id));

      const contactPersons = newlyAssignedContactPersons.map((obj) => ({
        childId: customInfo.id,
        contactPersonId: obj.id,
      }));
      const salesPersons = newlyAssignedSalesPersons.map((obj) => ({
        childId: customInfo.id,
        salesPersonId: obj.id,
      }));
      const payload = {
        contactPersons: contactPersons,
        salesPersons: salesPersons,
        removeContactPersons: {
          childId: customInfo.id,
          contactPersonIds: removedContactPersonsIds,
        },
        removeSalesPersons: {
          childId: customInfo.id,
          salesPersonIds: removedSalesPersonsIds,
        },
      };
      try {
        onCancelUpdate("");
        setLoading(true);
        //console.log(payload);
        const { data } = await assignContactSalesCustomerService(
          payload,
          accessToken
        );
        if (data?.data) {
          onCancel("");
          alert("Contacts Assigned successfully");
          getUpdatedList();
          setLoading(false);
          //dispatch(clearCustomerAccountDetails());
          //navigate('/customer-account');
          //window.location.reload();
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    if (submit) {
      assignContactSales();
      setSubmit(false);
    }
  }, [
    accessToken,
    contactPersonsSelected,
    customInfo.id,
    getUpdatedList,
    myCPset,
    mySPset,
    onCancel,
    onCancelUpdate,
    salesPersonsSelected,
    submit,
  ]);

  useEffect(() => {
    const getContactAndSalesListFromParent = async () => {
      try {
        setLoading(true);
        const { data } = await fetchParentAccountDetailsService(
          accessToken,
          initialCustomInfo.parentId
        );

        if (data.data) {
          const { contactPersons: cp, salesPersons: sp } = data.data;

          setLoading(false);
          return { cpe: cp ? cp : [], spe: sp ? sp : [] };
        }
      } catch (error) {
        setLoading(false);
        alert("Could not fetch the contact and sales persons list");
      }
    };
    const getChildAccountDetails = async ({
      cpe,
      spe,
    }: {
      cpe: IContactDetails[];
      spe: IContactDetails[];
    }) => {
      try {
        setLoading(true);
        const { data } = await fetchCustomerContactDetailsService(
          accessToken,
          initialCustomInfo.id
        );
        if (data.data) {
          const { contactPersons: cp, salesPersons: sp } = data.data; //already assigned sales/contact list
          const CPset: Set<string | number> = new Set(
            cp.map((c: { id: any }) => c.id)
          );
          const SPset: Set<string | number> = new Set(
            sp.map((c: { id: any }) => c.id)
          );
          setMySpSet(SPset);
          setMyCpSet(CPset);

          let reltsp: IContactDetails[] = [];
          if (spe) reltsp = spe.filter((sp) => !SPset.has(sp.id));
          let reltcp: IContactDetails[] = [];
          if (cpe) reltcp = cpe.filter((cp) => !CPset.has(cp.id));
          setSalesPersons(reltsp);
          setContactPersons(reltcp);
          setContactPersonsSelected(cp);
          setSalesPersonSelected(sp);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        alert("Could not fetch the contact and sales persons list");
      }
    };
    if (initialCustomInfo.parentId)
      getContactAndSalesListFromParent().then((res) => {
        getChildAccountDetails(res!);
      });
  }, [accessToken, initialCustomInfo]);

  if (loading) return <SpinnerLoader></SpinnerLoader>;

  return (
    <div className="content-sm page-bottom-space">
      <div className="form-wrap">
        <div className="border-bottom pb-1 d-flex justify-content-between align-items-center mb-3">
          <h4 className="mb-0 heading4-bold">
            {role === USER_ROLE.SUPER_ADMIN
              ? `Assign Customer Contact`
              : role === USER_ROLE.BUYER_ADMIN
              ? `Assign Contact Person`
              : `Assign Sales Person`}
          </h4>
          <p className="mb-0 font-size-13">*required field</p>
        </div>

        {(role === USER_ROLE.SUPER_ADMIN || role === USER_ROLE.BUYER_ADMIN) && (
          <div>
            <select
              className="mb-3 form-select"
              title={""}
              onChange={handleChangeContact}
              value={""}
              name={"contact"}
            >
              {<option value="">{"Select Contact Person "}</option>}
              {React.Children.toArray(
                contactPersons?.map((el) => (
                  <option value={el.id}>{el.name}</option>
                ))
              )}
            </select>
            {contactPersonsSelected?.length > 0 && (
              <ul className="list-group list-group-flush list-style-none">
                {React.Children.toArray(
                  contactPersonsSelected.map((contact, key) => (
                    <li
                      className="has-right-action mb-2"
                      key={`contact-${key}`}
                    >
                      <p className="mb-0 text-capitalize">{contact.name}</p>
                      <div className="action-wrap">
                        {/* {!myCPset.has(contact.id) && (
                          <button
                            type="button"
                            className="btn btn-link-danger"
                            onClick={() => removeContact(contact.id)}
                          >
                            <i className="fa-solid fa-xmark">{''}</i>
                          </button>
                        )} */}
                        {
                          <button
                            type="button"
                            className="btn btn-link-danger"
                            onClick={() => removeContact(contact.id)}
                          >
                            <i className="fa-solid fa-xmark">{""}</i>
                          </button>
                        }
                      </div>
                    </li>
                  ))
                )}
              </ul>
            )}
          </div>
        )}

        {(role === USER_ROLE.SUPER_ADMIN ||
          role === USER_ROLE.CONTACT_PERSON) && (
          <div>
            <select
              className="mb-3 form-select"
              title={""}
              onChange={handleChangeSales}
              value={""}
              name={"sales"}
            >
              {<option value="">{"Select Sales Person"}</option>}
              {React.Children.toArray(
                salesPersons?.map((el) => (
                  <option value={el.id}>{el.name}</option>
                ))
              )}
            </select>
            {salesPersonsSelected?.length > 0 && (
              <ul className="list-group list-group-flush list-style-none">
                {React.Children.toArray(
                  salesPersonsSelected.map((salePerson, key) => (
                    <li className="has-right-action mb-2" key={`sales-${key}`}>
                      <p className="mb-0 text-capitalize">{salePerson.name}</p>
                      <div className="action-wrap">
                        {/* {!mySPset.has(salePerson.id) && (
                          <button
                            type="button"
                            className="btn btn-link-danger"
                            onClick={() => removeSales(salePerson.id)}
                          >
                            <i className="fa-solid fa-xmark">{''}</i>
                          </button>
                        )} */}
                        {
                          <button
                            type="button"
                            className="btn btn-link-danger"
                            onClick={() => removeSales(salePerson.id)}
                          >
                            <i className="fa-solid fa-xmark">{""}</i>
                          </button>
                        }
                      </div>
                    </li>
                  ))
                )}
              </ul>
            )}
          </div>
        )}

        <div className="mt-3">
          <button type="button" className="btn btn-danger px-4" onClick={save}>
            {`Update`}
          </button>
          <button
            type="button"
            className="btn btn-outline-danger px-4 ms-3"
            onClick={() => onCancel("")}
          >
            {`Cancel`}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Assign;
