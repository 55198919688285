import React, { ChangeEvent, FC, useEffect, useState } from "react";
import SpinnerLoader from "ui/loader/SpinnerLoader";
import { IMAGES } from "assets/images/images";
import Input from "ui/input/Input";
import style from "./ShippingChargeConfig.module.scss";
import { useAppSelector } from "store/hooks";
import { getUserDetails } from "store/User.slice";
import { getShippingCost, updateShippingCharge } from "api/shippingCostService";
import { toast } from "react-toastify";

const _data = [
  {
    styleConcat: "Short Sleeve Tees",
    rate: "0.75",
    quantityPerLargeBox: "84",
    quantityPerMediumBox: "42",
    weightOfLargeBoxInKg: "15.5",
    weightOfMediumBoxInKg: "8.03",
  },
  {
    styleConcat: "Long Sleeve Tees",
    rate: "0.75",
    quantityPerLargeBox: "84",
    quantityPerMediumBox: "42",
    weightOfLargeBoxInKg: "15.5",
    weightOfMediumBoxInKg: "8.3",
  },
  {
    styleConcat: "Hoodie Tees",
    rate: "0.75",
    quantityPerLargeBox: "72",
    quantityPerMediumBox: "36",
    weightOfLargeBoxInKg: "15.5",
    weightOfMediumBoxInKg: "8.3",
  },
  {
    styleConcat: "Polo Tees",
    rate: "0.75",
    quantityPerLargeBox: "72",
    quantityPerMediumBox: "36",
    weightOfLargeBoxInKg: "15.5",
    weightOfMediumBoxInKg: "8.3",
  },
  {
    styleConcat: "1/4 Zip Sweats",
    rate: "2.25",
    quantityPerLargeBox: "24",
    quantityPerMediumBox: "12",
    weightOfLargeBoxInKg: "17.65",
    weightOfMediumBoxInKg: "9",
  },
  {
    styleConcat: "Hoodie Sweats",
    rate: "2.25",
    quantityPerLargeBox: "24",
    quantityPerMediumBox: "12",
    weightOfLargeBoxInKg: "17.65",
    weightOfMediumBoxInKg: "9",
  },
];

export interface IShippingChargeType {
  id: number;
  styleConcat: string;
  rate: string;
  quantityPerLargeBox: string;
  quantityPerMediumBox: string;
  weightOfLargeBoxInKg: string;
  weightOfMediumBoxInKg: string;
  [key: string]: string | number;
}

const ShippingChargeConfig: FC = () => {
  const { accessToken } = useAppSelector(getUserDetails);

  const [data, setData] = useState<IShippingChargeType[]>([]);
  const [loading, setLoading] = useState(false);

  const onInputChange = (e: ChangeEvent<HTMLInputElement>, i: number) => {
    const { name, value } = e.target;
    setData((prev) => {
      const oldData = [...prev];
      oldData[i][name] = value;
      return oldData;
    });
  };

  const onsubmit = async (item: IShippingChargeType) => {
    if (parseFloat(item.rate) < 0) {
      toast.error("Negative input is not valid");
      return;
    }
    try {
      await updateShippingCharge(item, item.id, accessToken);
      toast.success("Shipping charge updated successfully");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        const { data } = await getShippingCost(accessToken);
        setLoading(false);
        setData(data.data);
      } catch (e) {
        setLoading(false);
        console.log(e);
      }
    };
    getData();
  }, [accessToken]);

  if (loading) return <SpinnerLoader />;

  return (
    <div className="content-md page-bottom-space">
      <div className="border-bottom pb-1 d-flex justify-content-between align-items-center mb-2">
        <h4 className="mb-0 heading4-bold">Shipping Charge Setup</h4>
      </div>

      <div className="border-bottom row pb-1 d-flex justify-content-start align-items-center">
        <div className="col-lg-2">Style</div>
        <div className="col-lg-2">Price/Unit</div>
        <div className="col-lg-1">QTY Large Box</div>
        <div className="col-lg-1">QTY Medium Box</div>
        <div className="col-lg-2">Weight Large Box</div>
        <div className="col-lg-2">Weight Medium Box</div>
        <div className="col-lg-2">Action</div>
      </div>

      <div className="mt-2">
        {data.map((item, i) => (
          <div key={item.styleConcat} className="row">
            <div className="col-lg-2">{item.styleConcat}</div>
            <div className="col-lg-2 d-flex align-items-center">
              <div className={style.field}>
                <Input
                  sectionClass="mb-3"
                  value={item.rate}
                  onChange={(e) => onInputChange(e, i)}
                  label=""
                  type="number"
                  name="rate"
                  placeholder="Abc"
                  min={0.0001}
                />
                <span className={style.field__helper}>$</span>
              </div>
            </div>
            <div className="col-lg-1">
              <div className={style.field}>
                <Input
                  sectionClass="mb-3"
                  value={item.quantityPerLargeBox}
                  onChange={(e) => onInputChange(e, i)}
                  type="number"
                  label=""
                  name="quantityPerLargeBox"
                  placeholder="Quantity (Large Box)"
                  disabled
                />
                <span className={style.field__helper}>PS</span>
              </div>
            </div>
            <div className="col-lg-1">
              <div className={style.field}>
                <Input
                  sectionClass="mb-3"
                  value={item.quantityPerMediumBox}
                  onChange={(e) => onInputChange(e, i)}
                  type="number"
                  label=""
                  name="quantityPerMediumBox"
                  placeholder="Quantity (Medium Box)"
                  disabled
                />
                <span className={style.field__helper}>PS</span>
              </div>
            </div>
            <div className="col-lg-2">
              <div className={style.field}>
                <Input
                  sectionClass="mb-3"
                  value={item.weightOfLargeBoxInKg}
                  onChange={(e) => onInputChange(e, i)}
                  type="number"
                  label=""
                  name="weightOfLargeBoxInKg"
                  placeholder="Weight (Large Box)"
                  disabled
                />
                <span className={style.field__helper}>KG</span>
              </div>
            </div>
            <div className="col-lg-2">
              <div className={style.field}>
                <Input
                  sectionClass="mb-3"
                  value={item.weightOfMediumBoxInKg}
                  onChange={(e) => onInputChange(e, i)}
                  type="number"
                  label=""
                  name="weightOfMediumBoxInKg"
                  placeholder="Weight (Medium Box)"
                  disabled
                />
                <span className={style.field__helper}>KG</span>
              </div>
            </div>
            <div className="col-lg-2">
              <button
                className="btn btn-danger px-4"
                type="button"
                onClick={() => onsubmit(item)}
              >
                Update
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ShippingChargeConfig;
