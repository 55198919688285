import clsx from "clsx";
import * as React from "react";
import { toast } from "react-toastify";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { FormValues, Style, StylePrice } from "./interface";
import { getPayload, transformAsFormValues } from "./helper";
import * as Service from "api/inventoryService";

interface IProp {}

const StylesPricing: React.FC<IProp> = (): JSX.Element => {
  const formRef = React.useRef<HTMLFormElement>(null);
  const [refetch, setRefetch] = React.useState<boolean>(true);
  const [term, setTerm] = React.useState<string>("");
  const [styles, setStyles] = React.useState<Style[]>([]);
  const [editable, setEditable] = React.useState<boolean>(false);
  const [isSyncing, setIsSyncing] = React.useState<boolean>(false);

  const styleVisibilityObj = React.useMemo(() => {
    if (!term) return styles;
    const visibilityObj: any = {}
    for(let style of styles){
      if(!term){
        visibilityObj[style.id] = true
        continue
      }
      visibilityObj[style.id] = style.styleNumber.toLowerCase().includes(term.toLowerCase())
    }
    return visibilityObj
  }, [styles, term]);

  const methods = useForm<FormValues>({
    defaultValues: {
      dozen: [{ value: "" }],
      data: {},
    },
  });

  const { control } = methods;
  const isSubmitting = methods.formState.isSubmitting;
  const dirtyFields = methods.formState.dirtyFields;
  const { fields, append } = useFieldArray({ control, name: "dozen" });

  const startSyncing = async () => {
    setIsSyncing(true);
    Service.startStyleSync()
      .then(() => {
        setIsSyncing(true);
        toast.success("Style sync started...");
      })
      .catch((err) => {
        toast.error("failed to sync");
      });
  };

  const handleCustomClick = () => {
    if (!editable) {
      setEditable(true);
      return;
    }
    if (typeof formRef.current?.requestSubmit === "function") {
      formRef.current.requestSubmit();
    } else {
      formRef.current?.dispatchEvent(
        new CustomEvent("submit", { cancelable: true })
      );
    }
  };

  const onSubmit = async (allValues: FormValues) => {
    if (Object.values(allValues.data).some((v) => v?.includes("-"))) {
      toast.error("Negative input is not valid");
      return;
    }
    try {
      const payload = getPayload(dirtyFields, allValues);
      if (payload.stylePrices.length === 0 && payload.dozen.length === 0) {
        toast.success("You have not changed anything");
        setRefetch(true);
        setEditable(false);
        return;
      }
      await Service.updateStylePrice(payload);
      toast.success("saved successfully");
      setRefetch(true);
      setEditable(false);
    } catch (err) {
      toast.error("failed to save");
      setRefetch(true);
      setEditable(false);
    }
  };

  React.useEffect(() => {
    async function fetchAMStyles() {
      try {
        const [_styles, _dozen, _stylePrices] = await Promise.all([
          Service.fetchAllAMStyles() as unknown as Promise<Style[]>,
          Service.fetchAllDozen() as unknown as Promise<
            { id: string; value: string }[]
          >,
          Service.fetchAllStylePrices() as unknown as Promise<StylePrice[]>,
        ]);
        methods.reset();
        const __v = transformAsFormValues(_dozen, _stylePrices);
        methods.reset(__v);
        setStyles(_styles);
        setRefetch(false);
      } catch (err) {
        console.error(err);
        setRefetch(false);
      }
    }
    if (refetch) fetchAMStyles();
  }, [refetch]);

  React.useEffect(() => {
    if (isSyncing) {
      const controller = new AbortController();
      const signal = controller.signal;
      let id = setInterval(() => {
        (
          Service.checkStyleSync({ signal }) as unknown as Promise<
            "ACTIVE" | "INACTIVE"
          >
        )
          .then((status) => {
            if (status === "INACTIVE") {
              controller.abort();
              setRefetch(true);
              setIsSyncing(false);
              toast.success("Styles synced.");
            }
          })
          .catch((err) => console.log(err));
      }, 500);
      return function cleanup() {
        clearInterval(id);
      };
    }
  }, [isSyncing]);

  return (
    <>
      <div className="page-bottom-space" style={{ width: "991px" }}>
        <div className="border-bottom pb-1 mb-3 d-flex justify-content-between align-items-center">
          <h4 className="mb-0 heading4-bold">Styles Pricing</h4>
          <div className="">
            <button
              className="btn btn-danger me-2"
              disabled={isSubmitting || isSyncing}
              onClick={startSyncing}
            >
              <i
                className={clsx("fas fa-sync me-2", { "fa-spin": isSyncing })}
              ></i>
              Styles
            </button>
            <button
              className="btn btn-danger me-2"
              onClick={() => append({ dozenId: "", value: "" })}
              disabled={!editable || isSubmitting || isSyncing}
            >
              <i className="fa fa-plus me-2"></i>Dozen
            </button>
            <button
              className="btn btn-danger"
              disabled={isSubmitting || isSyncing}
              onClick={handleCustomClick}
              style={{ minWidth: "65px" }}
            >
              {editable ? (
                <>
                  <i className="fa-regular fa-floppy-disk me-2"></i>Save
                </>
              ) : (
                <>
                  <i className="fa fa-pencil me-2"></i>Edit
                </>
              )}
            </button>
          </div>
        </div>
        <form onSubmit={methods.handleSubmit(onSubmit)} ref={formRef}>
          <div className="overflow-auto" style={{ maxHeight: "600px" }}>
            <fieldset disabled={isSubmitting || isSyncing}>
            <table className="table custom-new-table">
              <thead>
                <tr>
                  <th>Styles</th>
                  <th
                   colSpan={fields.length}
                   >Dozen</th>
                </tr>
                <tr>
                  <th>
                    <div style={{ position: "relative" }}>
                      <i
                        className="fa fa-search"
                        aria-hidden="true"
                        style={{
                          position: "absolute",
                          left: "10px",
                          top: "10px",
                        }}
                      ></i>
                      <input
                        className="form-control"
                        value={term}
                        onChange={(e) => setTerm(e.target.value)}
                        style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
                      />
                    </div>
                  </th>
                  {fields.map((_field, i) => (
                    <th key={_field.id}>
                      <Controller
                        control={control}
                        name={`dozen.${i}.value`}
                        render={({ field }) => (
                          <input
                            className="form-control"
                            {...field}
                            disabled={!editable || isSubmitting}
                          />
                        )}
                      />
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {styles.map((style) => (
                  <tr key={style.styleId} className={clsx({ 'd-none': !styleVisibilityObj[style.id] })} >
                    <th style={{ minWidth: "150px" }}>{style.styleNumber}</th>
                    {fields.map((_, idx) => (
                      <td key={idx}>
                        <input
                          className="form-control"
                          {...methods.register(`data.item_${style.styleId}_${idx}`, { shouldUnregister: true })}
                          disabled={!editable || isSubmitting}
                        />
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
            </fieldset>
          </div>
        </form>
      </div>
    </>
  );
};

export default StylesPricing;
