import { resetPasswordService } from "api/userService";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useAppSelector } from "store/hooks";
import { getUserDetails } from "store/User.slice";

import Button from "ui/button/Button";
import Input from "ui/input/Input";
import styles from "../login/LoginPage.module.scss";
import Logo from "../../assets/images/logo.svg";
import StandardRecLogo from "../../assets/images/standardRecLogo.svg";

import { useHandleParams } from "hooks/useHandleParams";
import SpinnerLoader from "ui/loader/SpinnerLoader";
import { toast } from "react-toastify";
import { decryptWithAES } from "utils/AEShelper";

export interface ResetPasswordProps {}
const ResetPassword: React.FC<ResetPasswordProps> = () => {
  const navigate = useNavigate();
  // const location = useLocation();
  // const query = new URLSearchParams(location.search);
  // const token = query.get('token');
  const { token } = useHandleParams();
  const [getOtp, setGetOtp] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [info, setUsername] = useState({
    // userName: '',
    // otp: '',
    newPassword: "",
    confirmPassword: "",
  });
  const [loading, setLoading] = useState(false);

  const { accessToken } = useAppSelector(getUserDetails);

  const handleUserName = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUsername((prevState) => {
      return {
        ...prevState,
        [name]: value.trim(),
      };
    });
  };

  // const handleGetOtp = () => {
  //   if (validateUsername(info.userName)) {
  //     setGetOtp(true);
  //   }
  // };

  const handleResetPassword = (e: any) => {
    e.preventDefault();
    // validate password and reset password
    if (
      info.newPassword &&
      info.confirmPassword &&
      info.newPassword === info.confirmPassword
    ) {
      setSubmit(true);
    } else {
      toast.warn("New Password and confirm password does not match");
    }
  };

  useEffect(() => {
    const sendOtp = async () => {
      console.log("Sending otp to email");
    };
    if (getOtp) {
      sendOtp();
      setGetOtp(false);
    }
  }, [getOtp]);

  useEffect(() => {
    const resetPassword = async () => {
      if (accessToken || token) {
        setLoading(true);
        const payload = {
          newPassword: info.newPassword,
          accessToken: accessToken ? accessToken : token ? token : "",
        };
        try {
          let { data } = await resetPasswordService(payload);
          data.data = JSON.parse(await decryptWithAES(data.data));
          if (data?.data) {
            toast.success("Password changed successfully");
            setLoading(false);
            navigate("/");
          }
        } catch (error) {
          console.error(error);
          setLoading(false);
        }
      }
    };
    if (submit) {
      resetPassword();
      setSubmit(false);
    }
  }, [accessToken, info.newPassword, navigate, submit, token]);

  if (loading) return <SpinnerLoader />;
  return (
    <div className={styles.loginWrap}>
      <div className="container h-100 d-flex align-items-center justify-content-center">
        <form className={styles.loginForm} method="POST">
          <div className="text-center mb-3">
            <img
              className={`img-fluid ${styles.logo}`}
              src={StandardRecLogo}
              alt="logo"
            />
          </div>
          <div className={`card ${styles.formCard}`}>
            <div className={`card-body`}>
              <h3 className="heading3 mb-3 font-weight-normal">
                Create new password
              </h3>
              <Input
                sectionClass="mb-3"
                value={info.newPassword}
                type="password"
                placeholder="Enter new password"
                id="newPassword"
                name="newPassword"
                onChange={handleUserName}
              />
              <Input
                sectionClass="mb-3"
                value={info.confirmPassword}
                type="password"
                placeholder="Confirm new password"
                id="confirmPassword"
                name="confirmPassword"
                onChange={handleUserName}
              />
              <button
                className="btn btn-danger w-100"
                onClick={(e) => {
                  handleResetPassword(e);
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
