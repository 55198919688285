import { fetchAllCustomerAttributeService } from "api/masterDataService";
import { Attribute, IkeyValuePair, ITemplate } from "api/requestTypes";
import {
  deleteTemplateService,
  fetchAllTemplatesService,
  downloadTemplateDatabaseCSVService,
  downloadTemplateLogosCSVService,
} from "api/templateService";
import * as templateService from "api/templateService";
import ConfirmationModal from "components/confirmationModal/ConfirmationModal";
import CustomTooltip from "components/tooltip/Tooltip";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { useAppSelector } from "store/hooks";
import { getUserDetails } from "store/User.slice";
import SpinnerLoader from "ui/loader/SpinnerLoader";
import TemplatePricingModal from "./template-pricing/TemplatePricingModal";
import TemplateAddUpdateModal from "./TemplateAddUpdateModal";
import TemplateDetailsModal from "./TemplateDetailsModal";
import styles from "./Templates.module.scss";
import GlobalTemplateConfigDialog from "./templateSettingDialog/GlobalTemplateConfigDialog";
import GraphicsRuleModal from "pages/graphicRule/GraphicsRuleModal";
import TemplateLabelModal from "./TemplateLabelModal";
import LabelBasedFilter from "./LabelBasedFilter";
import TemplateLabelChips from "./TemplateLabelChips";
import GraphicDefaultModal from "./GraphicDefaultModal";

const defaultValue: ITemplate = {
  altCode: "",
  styleNumber: "",
  b2bWebTitle: "",
  name: "",
  style: "",
  subStyle: "",
  design: "Front",
  frontThumbnail: "",
  frontDocument: "",
  frontDescription: "",
  frontDescriptionVersion: "",
  allFrontDescription: "",
  backThumbnail: "",
  backDocument: "",
  backDescription: "",
  backDescriptionVersion: "",
  allBackDescription: "",
  logos: false,
  backLogos: false,
};

const Templates: FC = (): JSX.Element => {
  const { accessToken } = useAppSelector(getUserDetails);
  const [loading, setLoading] = useState(false);
  const [templateSelected, setTemplateSelected] = useState<{
    [key: string]: boolean;
  }>({});
  const [labels, setLabels] = useState([]);
  const [openBodySetup, setOpenBodySetup] = useState(false);
  const [refetchLabel, setRefetchLabel] = useState(true);
  const [selLabels, setSelLabels] = useState<{ [key: string]: boolean }>({});
  const [filterLabels, setFilterLabels] = useState<any>([]);
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [openLabelModal, setOpenLabelModal] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("Loading...");
  const [templateList, setTemplateList] = useState<ITemplate[]>([]);
  const [filteredTemplateList, setFilteredTemplateList] = useState<ITemplate[]>(
    []
  );
  const [deleteId, setDeleteId] = useState<string | number>("");
  const [temp, setTemp] = useState<ITemplate>({ ...defaultValue });
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [refetchTemplate, setRefetchTemplate] = useState(true);
  const [templateAddUpdateModalOpen, setTemplateAddUpdateModalOpen] =
    useState(false);
  const [templateDetailsModalOpen, setTemplateDetailsModalOpen] =
    useState(false);
  const [templateSettingModalOpen, setTemplateSettingModalOpen] =
    useState(false);
  const [templatePricingModalOpen, setTemplatePricingModalOpen] =
    useState(false);
  const [keyList, setKeyList] = useState<IkeyValuePair[]>([]);
  const [isGraphiListModal, setIsGraphiListModal] = useState(false);
  const [isGraphicDefaultModal, setIsGraphicDefaultModal] = useState(false);
  const handleTemplateDetailsCallBack = () => {
    setTemplateAddUpdateModalOpen(true);
    setTemplateDetailsModalOpen(false);
  };
  const toggleLableModal = () => setOpenLabelModal((prev) => !prev);
  const handleFilter = (name: string, checked: boolean) =>
    setSelLabels((prev) => ({ ...prev, [name]: checked }));

  const handleTemplateAddUpdateModalCallBack = (
    template: ITemplate,
    action: string
  ) => {
    if (action === "add") setTemplateList([...templateList, template]);
    else if (action === "update") {
      const t = templateList.map((el) =>
        el.id == template.id ? { ...template } : el
      );
      setTemplateList(t);
    } else {
      setTemp(defaultValue);
    }
  };
  const handleDeleteTemplate = async (id: number | string) => {
    try {
      setLoading(true);
      const { data } = await deleteTemplateService(id, accessToken);
      if (data.data) {
        let rslt = templateList.filter((el) => el.id != id);
        setTemplateList(rslt);
        setLoading(false);
        alert("Deleted successfully");
      }
    } catch (error) {
      console.error(error);
      alert("Unable to delete");
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const downloadCSV = async (currentTemplate: ITemplate) => {
    const temp = templateList.find((e) => e.id === currentTemplate.id);

    async function dwnldDatabaseCSVFile({
      design,
      isFrBk,
    }: {
      design: "front" | "back";
      isFrBk: boolean;
    }) {
      const payload = {
        templateId: currentTemplate.id,
        design,
      };
      setLoading(true);
      setLoadingMessage("Downloding...please wait");
      const { data } = await downloadTemplateDatabaseCSVService(
        payload,
        accessToken
      );
      setLoadingMessage("Loading...");
      setLoading(false);
      const csvContent = "data:text/csv;charset=utf-8," + data;
      const encodedUri = encodeURI(csvContent);

      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      let designAbbr = "";
      if (isFrBk) {
        designAbbr = design === "front" ? "-FR" : "-BK";
      }
      const fileName = `${temp?.name}${designAbbr}_${temp?.id}_database`;
      link.setAttribute("download", fileName);
      document.body.appendChild(link); // Required for FF
      link.click();
      link.remove();
    }

    try {
      let isFrBk = false;
      if (temp?.design === "Front and Back") {
        isFrBk = true;
        await dwnldDatabaseCSVFile({ design: "back", isFrBk });
      }
      await dwnldDatabaseCSVFile({ design: "front", isFrBk });
    } catch (error) {
      console.error(error);
      //toast.warn('File unavailable');
    } finally {
      setLoading(false);
      setLoadingMessage("Loading...");
    }
  };
  const downloadCSVForLogos = async (currentTemplate: ITemplate) => {
    const temp = templateList.find((e) => e.id === currentTemplate.id);
    if (temp?.logos !== true && temp?.backLogos !== true) return;

    async function dwnldLogosCSVFile({
      design,
      isFrBk,
    }: {
      design: "front" | "back";
      isFrBk: boolean;
    }) {
      const payload = {
        templateId: currentTemplate.id,
        design,
      };
      setLoading(true);
      setLoadingMessage("Downloading...please wait");
      const { data } = await downloadTemplateLogosCSVService(
        payload,
        accessToken
      );
      setLoading(false);
      setLoadingMessage("Loading...");
      const csvContent = "data:text/csv;charset=utf-8," + data;
      const encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      let designAbbr = "";
      if (isFrBk) {
        designAbbr = design === "front" ? "-FR" : "-BK";
      }
      const fileName = `${temp?.name}${designAbbr}_${temp?.id}_logos`;
      link.setAttribute("download", fileName);
      document.body.appendChild(link); // Required for FF
      link.click();
      link.remove();
    }

    try {
      let isFrBk = false;
      if (temp?.design === "Front and Back") {
        isFrBk = true;
        await dwnldLogosCSVFile({ design: "back", isFrBk });
      }
      await dwnldLogosCSVFile({ design: "front", isFrBk });
    } catch (error) {
      console.error(error);
      //toast.warn('File unavailable');
    } finally {
      setLoading(false);
      setLoadingMessage("Loading...");
    }
  };
  const downloadTemplatePdf = async (currentTemplate: ITemplate) => {
    const temp = templateList.find((e) => e.id === currentTemplate.id);
    if (temp?.frontDocument) {
      let url = temp?.frontDocument;
      setLoading(true);
      fetch(url).then((response) => {
        setLoadingMessage("Downloading...please wait");
        response.blob().then((blob) => {
          setLoadingMessage("Loading...");
          // Creating new object of PDF file
          const fileURL = window.URL.createObjectURL(blob);
          // Setting various property values
          let alink = document.createElement("a");
          alink.href = fileURL;
          alink.download = `${url.split("/").pop()}`;
          alink.click();
          alink.remove();
        });
      });
      setLoading(false);
    }
    if (temp?.backDocument) {
      let url = temp?.backDocument;
      setLoading(true);
      fetch(url).then((response) => {
        setLoadingMessage("Downloading...please wait");
        response.blob().then((blob) => {
          setLoadingMessage("Loading...");
          // Creating new object of PDF file
          const fileURL = window.URL.createObjectURL(blob);
          // Setting various property values
          let alink = document.createElement("a");
          alink.href = fileURL;
          alink.download = `${url.split("/").pop()}`;
          alink.click();
          alink.remove();
        });
      });
      setLoading(false);
    }
  };
  const downloadFiles = async (currentTemplate: ITemplate) => {
    try {
      downloadCSV(currentTemplate).then(() => {
        downloadCSVForLogos(currentTemplate).then(() => {
          downloadTemplatePdf(currentTemplate);
        });
      });
    } catch (error) {
      console.error();
    }
  };

  useEffect(() => {
    async function getAllLabes() {
      try {
        const response = await templateService.fetchLabels(accessToken);
        setLabels(response.data.data);
      } catch (err) {
        console.error(err);
      } finally {
        setRefetchLabel(false);
      }
    }
    getAllLabes();
  }, [selLabels, refetchLabel]);

  useEffect(() => {
    setFilterLabels(labels.filter((item: any) => selLabels[item.id]));
  }, [selLabels, labels]);

  useEffect(() => {
    if (!openLabelModal) {
      setSelLabels({});
      setTemplateSelected({});
    }
  }, [openLabelModal]);

  useEffect(() => {
    const getAttributeList = async () => {
      try {
        setLoading(true);
        const { data } = await fetchAllCustomerAttributeService(accessToken);
        if (data.data) {
          const { categories, keys } = data.data;
          const kys: Attribute[] = keys;
          const customDataKey = kys.map((k) => ({
            id: "",
            key: k.attributeName,
            value: "",
          }));
          setKeyList(customDataKey);
        }
        setLoading(false);
      } catch (error) {
        console.error(error);
        alert("Could not fetch the attributes");
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };
    getAttributeList();
  }, [accessToken]);

  useEffect(() => {
    if (!refetchTemplate) return;
    const getTemplateList = async () => {
      try {
        setLoading(true);
        const { data } = await fetchAllTemplatesService(accessToken);
        if (data.data) {
          const list = data.data.sort((a: any, b: any) => a.id - b.id);
          setTemplateList(list);
          setFilteredTemplateList(list);
        }
        setLoading(false);
      } catch (error) {
        console.error(error);
        alert("Could not fetch the templates");
        setLoading(false);
      } finally {
        setLoading(false);
        setRefetchTemplate(false);
        setTemplateSelected({});
      }
    };
    getTemplateList();
  }, [accessToken, refetchTemplate]);

  const handleConfirmCallBack = () => {
    handleDeleteTemplate(deleteId);
  };

  const handleCheck = (e: ChangeEvent<HTMLInputElement>) => {
    setTemplateSelected((prev) => ({
      ...prev,
      [e.target.name]: e.target.checked,
    }));
  };

  // if (loading) return <SpinnerLoader message={loadingMessage || ""} />;

  const templaesCheckbox = {
    position: "absolute",
  };

  return (
    <div className="content-md page-bottom-space has-heading-sticky">
      <div className="border-bottom pb-1 mb-3 d-flex justify-content-between align-items-center heading-wrap">
        <button
          className="btn btn-danger"
          onClick={() => setIsGraphicDefaultModal(true)}
        >
          Open Default
        </button>
        <div className="d-flex align-items-center">
          <h4 className="mb-0 heading4-bold me-2">Templates</h4>
          <div style={{ marginTop: "-8px" }}>
            {filterLabels.map((item: any) => (
              <span className={`${styles.chipsItem} hasClose`}>
                {item.title}
                <button
                  className="btn btn-link close-btn"
                  onClick={() => handleFilter(item.id, false)}
                >
                  <span className="fa fa-times-circle text-danger"></span>
                </button>
              </span>
            ))}
          </div>
        </div>
        <div className="d-flex">
          <button onClick={() => setOpenBodySetup(true)} className="btn btn-sm">
            New
          </button>
          <div className={`label-dropdown ${openLabelModal ? "active" : ""}`}>
            <button
              type="button"
              className="btn btn-link"
              onClick={() => {
                setOpenLabelModal(true);
                setOpenFilter(false);
              }}
            >
              <i className="icon-label text-danger me-2"></i>
            </button>
            <TemplateLabelModal
              accessToken={accessToken}
              templateList={templateList}
              setRefetchTemplate={setRefetchTemplate}
              templateSelected={templateSelected}
              handleClose={() => setOpenLabelModal(false)}
              labels={labels}
              setRefetchLabel={setRefetchLabel}
            />
          </div>
          <div className={`label-dropdown ${openFilter ? "active" : ""}`}>
            <button
              type="button"
              className="btn btn-link"
              onClick={() => {
                setOpenFilter(true);
                setOpenLabelModal(false);
              }}
            >
              <i className="icon-filter text-danger me-2"></i>
            </button>
            <LabelBasedFilter
              accessToken={accessToken}
              selLabels={selLabels}
              setFilterLabels={setFilterLabels}
              setSelLabels={setSelLabels}
              open={openFilter}
              handleFilter={handleFilter}
              templateList={templateList}
              labels={labels}
              setFilteredTemplateList={setFilteredTemplateList}
              handleClose={() => setOpenFilter(false)}
            />
          </div>
          <button
            type="button"
            className="btn btn-link"
            onClick={() => {
              setTemp(defaultValue);
              setTemplateAddUpdateModalOpen(true);
              setOpenFilter(false);
              setOpenLabelModal(false);
            }}
          >
            <i className="fa-solid fa-circle-plus text-danger me-2"></i>
          </button>
        </div>
      </div>

      {/* <Accordion defaultActiveKey="0" className="template-accordion">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <div className="template-header-wrap">
              <div className="template-header-left">
                <div className="custom-checkbox grey-shade me-1 ms-2">
                  <input
                    className="custom-control-input"
                    type="checkbox"
                    id="check1"
                    name="check1"
                    checked={false}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="check1"
                  ></label>
                </div>
                <div className="template-header-img">
                  <img
                    src="https://stdrec.s3.amazonaws.com/assets/images/U101-T002.png"
                    alt=""
                  />
                  <p>
                    TBD <span className="badge">svg</span>
                  </p>
                  <i className="fa-solid fa-chevron-down"></i>
                </div>
              </div>
              <div className="action-wrap" style={{ display: "block" }}>
                <CustomTooltip msg={"Graphic Rules"}>
                  <button
                    className="btn btn-link-danger ms-3"
                    onClick={() => {
                      setIsGraphiListModal(true);
                      setTemp(temp);
                    }}
                    // disabled={temp.status === 'Ready' ? false : true}
                  >
                    <i className="fa-solid fa-palette"></i>
                  </button>
                </CustomTooltip>
                <CustomTooltip msg={"Download"}>
                  <button
                    className="btn btn-link-danger ms-3"
                    onClick={() => {
                      downloadFiles(temp);
                    }}
                    // disabled={temp.status === 'Ready' ? false : true}
                  >
                    <i className="fa-solid fa-cloud-arrow-down"></i>
                  </button>
                </CustomTooltip>
                <CustomTooltip msg={"Pricing"}>
                  <button
                    className="btn btn-link-danger ms-3"
                    type="button"
                    onClick={() => {
                      setTemp(temp);
                      setTemplatePricingModalOpen(true);
                    }}
                  >
                    <i className="fa-solid fa-money-bills">{""}</i>
                  </button>
                </CustomTooltip>
                <CustomTooltip msg={"Settings"}>
                  <button
                    className="btn btn-link-danger ms-3"
                    type="button"
                    onClick={() => {
                      setTemp(temp);
                      setTemplateSettingModalOpen(true);
                    }}
                  >
                    <i className="fa-solid fa-gears">{""}</i>
                  </button>
                </CustomTooltip>
                <CustomTooltip msg={"Edit Template"}>
                  <button
                    className="btn btn-link-danger ms-3"
                    type="button"
                    onClick={() => {
                      setTemp(temp);
                      setTemplateAddUpdateModalOpen(true);
                    }}
                  >
                    <i className="fa-solid fa-pen">{""}</i>
                  </button>
                </CustomTooltip>
                <CustomTooltip msg={"Delete Template"}>
                  <button
                    className="btn btn-link-danger ms-3"
                    type="button"
                    onClick={() => {
                      setOpenConfirmation(true);
                      setDeleteId(temp.id!);
                    }}
                  >
                    <i className="fa-solid fa-trash-can">{""}</i>
                  </button>
                </CustomTooltip>
              </div>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <div className="template-header-wrap">
              <div className="template-header-left">
                <div className="custom-checkbox grey-shade me-1 ms-2">
                  <input
                    className="custom-control-input"
                    type="checkbox"
                    id="check1"
                    name="check1"
                    checked={false}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="check1"
                  ></label>
                </div>
                <div className="template-header-img">
                  <img
                    src="https://stdrec.s3.amazonaws.com/assets/images/U101-T002.png"
                    alt=""
                  />
                  <p>
                    TBD <span className="badge">svg</span>
                  </p>
                  <i className="fa-solid fa-chevron-down"></i>
                </div>
              </div>
              <div className="action-wrap" style={{ display: "block" }}>
                <CustomTooltip msg={"Graphic Rules"}>
                  <button
                    className="btn btn-link-danger ms-3"
                    onClick={() => {
                      setIsGraphiListModal(true);
                      setTemp(temp);
                    }}
                    // disabled={temp.status === 'Ready' ? false : true}
                  >
                    <i className="fa-solid fa-palette"></i>
                  </button>
                </CustomTooltip>
                <CustomTooltip msg={"Download"}>
                  <button
                    className="btn btn-link-danger ms-3"
                    onClick={() => {
                      downloadFiles(temp);
                    }}
                    // disabled={temp.status === 'Ready' ? false : true}
                  >
                    <i className="fa-solid fa-cloud-arrow-down"></i>
                  </button>
                </CustomTooltip>
                <CustomTooltip msg={"Pricing"}>
                  <button
                    className="btn btn-link-danger ms-3"
                    type="button"
                    onClick={() => {
                      setTemp(temp);
                      setTemplatePricingModalOpen(true);
                    }}
                  >
                    <i className="fa-solid fa-money-bills">{""}</i>
                  </button>
                </CustomTooltip>
                <CustomTooltip msg={"Settings"}>
                  <button
                    className="btn btn-link-danger ms-3"
                    type="button"
                    onClick={() => {
                      setTemp(temp);
                      setTemplateSettingModalOpen(true);
                    }}
                  >
                    <i className="fa-solid fa-gears">{""}</i>
                  </button>
                </CustomTooltip>
                <CustomTooltip msg={"Edit Template"}>
                  <button
                    className="btn btn-link-danger ms-3"
                    type="button"
                    onClick={() => {
                      setTemp(temp);
                      setTemplateAddUpdateModalOpen(true);
                    }}
                  >
                    <i className="fa-solid fa-pen">{""}</i>
                  </button>
                </CustomTooltip>
                <CustomTooltip msg={"Delete Template"}>
                  <button
                    className="btn btn-link-danger ms-3"
                    type="button"
                    onClick={() => {
                      setOpenConfirmation(true);
                      setDeleteId(temp.id!);
                    }}
                  >
                    <i className="fa-solid fa-trash-can">{""}</i>
                  </button>
                </CustomTooltip>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            <div className="template-header-wrap">
              <div className="template-header-left">
                <div className="custom-checkbox grey-shade me-1 ms-2">
                  <input
                    className="custom-control-input"
                    type="checkbox"
                    id="check1"
                    name="check1"
                    checked={false}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="check1"
                  ></label>
                </div>
                <div className="template-header-img">
                  <img
                    src="https://stdrec.s3.amazonaws.com/assets/images/U101-T002.png"
                    alt=""
                  />
                  <p>
                    TBD <span className="badge">svg</span>
                  </p>
                  <i className="fa-solid fa-chevron-down"></i>
                </div>
              </div>
              <div className="action-wrap" style={{ display: "block" }}>
                <CustomTooltip msg={"Graphic Rules"}>
                  <button
                    className="btn btn-link-danger ms-3"
                    onClick={() => {
                      setIsGraphiListModal(true);
                      setTemp(temp);
                    }}
                    // disabled={temp.status === 'Ready' ? false : true}
                  >
                    <i className="fa-solid fa-palette"></i>
                  </button>
                </CustomTooltip>
                <CustomTooltip msg={"Download"}>
                  <button
                    className="btn btn-link-danger ms-3"
                    onClick={() => {
                      downloadFiles(temp);
                    }}
                    // disabled={temp.status === 'Ready' ? false : true}
                  >
                    <i className="fa-solid fa-cloud-arrow-down"></i>
                  </button>
                </CustomTooltip>
                <CustomTooltip msg={"Pricing"}>
                  <button
                    className="btn btn-link-danger ms-3"
                    type="button"
                    onClick={() => {
                      setTemp(temp);
                      setTemplatePricingModalOpen(true);
                    }}
                  >
                    <i className="fa-solid fa-money-bills">{""}</i>
                  </button>
                </CustomTooltip>
                <CustomTooltip msg={"Settings"}>
                  <button
                    className="btn btn-link-danger ms-3"
                    type="button"
                    onClick={() => {
                      setTemp(temp);
                      setTemplateSettingModalOpen(true);
                    }}
                  >
                    <i className="fa-solid fa-gears">{""}</i>
                  </button>
                </CustomTooltip>
                <CustomTooltip msg={"Edit Template"}>
                  <button
                    className="btn btn-link-danger ms-3"
                    type="button"
                    onClick={() => {
                      setTemp(temp);
                      setTemplateAddUpdateModalOpen(true);
                    }}
                  >
                    <i className="fa-solid fa-pen">{""}</i>
                  </button>
                </CustomTooltip>
                <CustomTooltip msg={"Delete Template"}>
                  <button
                    className="btn btn-link-danger ms-3"
                    type="button"
                    onClick={() => {
                      setOpenConfirmation(true);
                      setDeleteId(temp.id!);
                    }}
                  >
                    <i className="fa-solid fa-trash-can">{""}</i>
                  </button>
                </CustomTooltip>
              </div>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <div className="template-header-wrap">
              <div className="template-header-left">
                <div className="custom-checkbox grey-shade me-1 ms-2">
                  <input
                    className="custom-control-input"
                    type="checkbox"
                    id="check1"
                    name="check1"
                    checked={false}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="check1"
                  ></label>
                </div>
                <div className="template-header-img">
                  <img
                    src="https://stdrec.s3.amazonaws.com/assets/images/U101-T002.png"
                    alt=""
                  />
                  <p>
                    TBD <span className="badge">svg</span>
                  </p>
                  <i className="fa-solid fa-chevron-down"></i>
                </div>
              </div>
              <div className="action-wrap" style={{ display: "block" }}>
                <CustomTooltip msg={"Graphic Rules"}>
                  <button
                    className="btn btn-link-danger ms-3"
                    onClick={() => {
                      setIsGraphiListModal(true);
                      setTemp(temp);
                    }}
                    // disabled={temp.status === 'Ready' ? false : true}
                  >
                    <i className="fa-solid fa-palette"></i>
                  </button>
                </CustomTooltip>
                <CustomTooltip msg={"Download"}>
                  <button
                    className="btn btn-link-danger ms-3"
                    onClick={() => {
                      downloadFiles(temp);
                    }}
                    // disabled={temp.status === 'Ready' ? false : true}
                  >
                    <i className="fa-solid fa-cloud-arrow-down"></i>
                  </button>
                </CustomTooltip>
                <CustomTooltip msg={"Pricing"}>
                  <button
                    className="btn btn-link-danger ms-3"
                    type="button"
                    onClick={() => {
                      setTemp(temp);
                      setTemplatePricingModalOpen(true);
                    }}
                  >
                    <i className="fa-solid fa-money-bills">{""}</i>
                  </button>
                </CustomTooltip>
                <CustomTooltip msg={"Settings"}>
                  <button
                    className="btn btn-link-danger ms-3"
                    type="button"
                    onClick={() => {
                      setTemp(temp);
                      setTemplateSettingModalOpen(true);
                    }}
                  >
                    <i className="fa-solid fa-gears">{""}</i>
                  </button>
                </CustomTooltip>
                <CustomTooltip msg={"Edit Template"}>
                  <button
                    className="btn btn-link-danger ms-3"
                    type="button"
                    onClick={() => {
                      setTemp(temp);
                      setTemplateAddUpdateModalOpen(true);
                    }}
                  >
                    <i className="fa-solid fa-pen">{""}</i>
                  </button>
                </CustomTooltip>
                <CustomTooltip msg={"Delete Template"}>
                  <button
                    className="btn btn-link-danger ms-3"
                    type="button"
                    onClick={() => {
                      setOpenConfirmation(true);
                      setDeleteId(temp.id!);
                    }}
                  >
                    <i className="fa-solid fa-trash-can">{""}</i>
                  </button>
                </CustomTooltip>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion> */}

      {filteredTemplateList.length > 0 && (
        <ul className="list-group list-group-flush list-style-none mb-2">
          {filteredTemplateList.map((temp: any, index) => {
            return (
              <li className={styles.templatesListItem} key={temp.id}>
                <div
                  className="has-right-action d-flex align-items-center"
                  style={{ paddingRight: "13rem" }}
                >
                  <div
                    onClick={() => {
                      setTemp(temp);
                      setTemplateDetailsModalOpen(true);
                    }}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <div className="avatar avatar-lg border-rounded">
                      {/* avatar-xl for bigger images */}
                      <div className={styles.image_container}>
                        {temp.design === "Front and Back" && (
                          <div className={styles.back_img}>
                            <img src={temp.backThumbnail} alt="" />
                          </div>
                        )}
                        <div
                          className={`${
                            temp.design === "Front and Back"
                              ? styles.front_img
                              : styles.noBackImage
                          }`}
                        >
                          <img src={temp.frontThumbnail} alt="" />
                        </div>
                      </div>
                      {/* <img
                        className="img-fluid object-fit-contain"
                        src={temp.frontThumbnail}
                        alt=".."
                      />*/}
                    </div>
                    <p className="mb-0 text-capitalize">
                      {temp.name}&nbsp;(ID-{temp.id})
                    </p>
                    <div className={styles.chipsWrap}>
                      {temp.TemplateLabels?.length ? (
                        <TemplateLabelChips
                          templateLabels={temp.TemplateLabels}
                        />
                      ) : null}
                    </div>
                  </div>

                  <div
                    className="custom-checkbox grey-shade me-1 ms-2"
                    style={{
                      position: "absolute",
                      left: "0",
                      top: "50%",
                      transform: "translateY(-50%)",
                    }}
                  >
                    <input
                      className="custom-control-input"
                      type="checkbox"
                      id={temp.id as string}
                      name={temp.id as string}
                      checked={templateSelected[temp.id as string]}
                      onChange={handleCheck}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor={temp.id as string}
                    ></label>
                  </div>
                  <div className="action-wrap" style={{ display: "block" }}>
                    <CustomTooltip msg={"Graphic Rules"}>
                      <button
                        className="btn btn-link-danger ms-3"
                        onClick={() => {
                          setIsGraphiListModal(true);
                          setTemp(temp);
                        }}
                        // disabled={temp.status === 'Ready' ? false : true}
                      >
                        <i className="fa-solid fa-palette"></i>
                      </button>
                    </CustomTooltip>
                    <CustomTooltip msg={"Download"}>
                      <button
                        className="btn btn-link-danger ms-3"
                        onClick={() => {
                          downloadFiles(temp);
                        }}
                        // disabled={temp.status === 'Ready' ? false : true}
                      >
                        <i className="fa-solid fa-cloud-arrow-down"></i>
                      </button>
                    </CustomTooltip>
                    <CustomTooltip msg={"Pricing"}>
                      <button
                        className="btn btn-link-danger ms-3"
                        type="button"
                        onClick={() => {
                          setTemp(temp);
                          setTemplatePricingModalOpen(true);
                        }}
                      >
                        <i className="fa-solid fa-money-bills">{""}</i>
                      </button>
                    </CustomTooltip>
                    <CustomTooltip msg={"Settings"}>
                      <button
                        className="btn btn-link-danger ms-3"
                        type="button"
                        onClick={() => {
                          setTemp(temp);
                          setTemplateSettingModalOpen(true);
                        }}
                      >
                        <i className="fa-solid fa-gears">{""}</i>
                      </button>
                    </CustomTooltip>
                    <CustomTooltip msg={"Edit Template"}>
                      <button
                        className="btn btn-link-danger ms-3"
                        type="button"
                        onClick={() => {
                          setTemp(temp);
                          setTemplateAddUpdateModalOpen(true);
                        }}
                      >
                        <i className="fa-solid fa-pen">{""}</i>
                      </button>
                    </CustomTooltip>
                    <CustomTooltip msg={"Delete Template"}>
                      <button
                        className="btn btn-link-danger ms-3"
                        type="button"
                        onClick={() => {
                          setOpenConfirmation(true);
                          setDeleteId(temp.id!);
                        }}
                      >
                        <i className="fa-solid fa-trash-can">{""}</i>
                      </button>
                    </CustomTooltip>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      )}
      {templateDetailsModalOpen && (
        <TemplateDetailsModal
          open={templateDetailsModalOpen}
          setOpen={setTemplateDetailsModalOpen}
          template={temp}
          handleCallBack={handleTemplateDetailsCallBack}
        />
      )}
      {templateAddUpdateModalOpen && (
        <TemplateAddUpdateModal
          open={templateAddUpdateModalOpen}
          setOpen={setTemplateAddUpdateModalOpen}
          template={temp}
          handleCallBack={handleTemplateAddUpdateModalCallBack}
        />
      )}
      {openConfirmation && (
        <ConfirmationModal
          open={openConfirmation}
          setOpen={setOpenConfirmation}
          confirmationMessage="Are you sure you want to delete?"
          handleCallBack={handleConfirmCallBack}
        />
      )}
      {templateSettingModalOpen && (
        <GlobalTemplateConfigDialog
          isOpen={templateSettingModalOpen}
          setIsOpen={setTemplateSettingModalOpen}
          template={temp}
          currentTemplateId={temp.id ? temp.id : ""}
          keys={keyList}
        />
      )}
      {templatePricingModalOpen && (
        <TemplatePricingModal
          open={templatePricingModalOpen}
          setOpen={setTemplatePricingModalOpen}
          template={temp}
          currentTemplateId={temp.id ? temp.id : ""}
        />
      )}
      {isGraphiListModal && (
        <GraphicsRuleModal
          template={temp}
          show={isGraphiListModal}
          handleSuccess={() => {
            setIsGraphiListModal(false);
          }}
          onHide={() => {
            setIsGraphiListModal(false);
          }}
        />
      )}
      {isGraphicDefaultModal && (
        <GraphicDefaultModal
          show={isGraphicDefaultModal}
          onHide={() => setIsGraphicDefaultModal(false)}
        />
      )}
    </div>
  );
};

export default Templates;
