import { uploadCustomerContactLogosService } from "api/customerService";
import { fetchUserExistanceService } from "api/userService";

export const validateUsername = (userName: string) => {
  if (userName) {
    return true;
  }
  return false;
};
export const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};
export const validateNumber = (number: string) => {
  return String(number).toLowerCase().match(/^\d+$/);
};

export const isAlreadyExisted = (email: string, emailList: string[]) => {
  if (email && !!emailList.length) {
    return emailList.includes(email);
  }
  return false;
};
export const uploadFiles = async (files: File[], accessToken: string) => {
  const uploadUrl = [];
  for (let i = 0; i < files.length; i++) {
    if (files[i]) {
      try {
        const formData = new FormData();
        formData.append("file", files[i]);

        const res = await uploadCustomerContactLogosService(
          formData,
          accessToken
        );
        const data = res?.data?.data;
        if (data) {
          uploadUrl.push(data.url);
        }
      } catch (error) {
        console.error(error);
      }
    } else uploadUrl.push("");
  }
  return uploadUrl;
};

export const isEmailAlreadyExist = async (
  accessToken: string,
  email: string
) => {
  try {
    const { data } = await fetchUserExistanceService(accessToken, {
      email,
    });
    if (data.msg && data.msg === "Success") {
      return true;
    }
    return false;
  } catch (e) {
    return false;
  }
};
export const checkExistingEmail = async (
  accessToken: string,
  email: string
) => {
  try {
    const { data } = await fetchUserExistanceService(accessToken, {
      email,
    });
    if (data.msg && data.msg === "Success") {
      return {
        name: data.data.name,
        email: data.data.email
      };
    }
    return null;
  } catch (e) {
    return null;
  }
};

export const fColor = (color: string): string => {
  return color?.split('-')?.[0].trim()
}

export const fCurrency = (value: number) => Number((value).toFixed(2))

export const clone = (obj: any) => JSON.parse(JSON.stringify(obj))

export const isEmptyObj = (obj: any) => {
  if (obj === null || obj === undefined) return true
  for (let key in obj) {
    return false
  }
  return true
}

export const checkDescriptionFileName = (name: any, descriptionVersion = 0) => {
  if (!descriptionVersion) {
    return;
  }
  let fname = name.split("/").pop();
  console.log(fname, descriptionVersion)
  const regex = /.*\-V(\d+).json$/;
  const matched = fname.match(regex);

  if (matched) {
    const [_, version] = fname.match(regex);
    if (version != descriptionVersion + 1) {
      throw "unexpected file name.";
    }
    return;
  } else {
    throw "unexpected file name.";
  }
};