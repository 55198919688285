import React, { ChangeEvent, FC, useEffect, useState } from "react";
import Input from "../../ui/input/Input";

import { ITemplate } from "api/requestTypes";
import { checkDescriptionFileName, uploadFiles } from "utils/helperFunctions";
import { getUserDetails } from "store/User.slice";
import { useAppSelector } from "store/hooks";
import {
  colorSwatchesAddService,
  createTemplatesService,
  deleteTemplatesService,
  fetchTemplateDetailsService,
  updateTemplateService,
} from "api/templateService";
import SpinnerLoader from "ui/loader/SpinnerLoader";
import { Modal } from "react-bootstrap";
import { fetchAllStyleService } from "api/style";
import StyleSelectUI from "./StyleSelectUI";
import { toast } from "react-toastify";
import { fetchLocalBlankGarments } from "api/inventoryService";

const getColorsFromJsonData = (swatches: any) => {
  let colorsArr: any = {
    graphicColors: [],
    graphicColors2: [],
    bodyColors: [],
  };
  swatches.forEach((swatch: any) => {
    const isGraphicColor = () =>
      swatch.name === "graphic color" || swatch.name === "graphic color 1";
    const isGraphic2Color = () => swatch.name === "graphic color 2";
    const isBodyColor = () => swatch.name === "body color";
    if (swatch.LtDk) {
      if (isGraphicColor()) {
        colorsArr["graphicColors"] = [...swatch.light, ...swatch.dark];
      } else if (isGraphic2Color()) {
        colorsArr["graphicColors2"] = [...swatch.light, ...swatch.dark];
      } else if (isBodyColor()) {
        colorsArr["bodyColors"] = [...swatch.light, ...swatch.dark];
      }
    } else {
      if (isGraphicColor()) {
        colorsArr["graphicColors"] = [...swatch.options];
      } else if (isGraphic2Color()) {
        colorsArr["graphicColors2"] = [...swatch.options];
      } else if (isBodyColor()) {
        colorsArr["bodyColors"] = [...swatch.options];
      }
    }
  });
  const colors = [
    { graphicColors: colorsArr.graphicColors },
    { bodyColors: colorsArr.bodyColors },
    { graphicColors2: colorsArr.graphicColors2 },
  ];
  return colors;
};

const getLayerValues = (design: "front" | "back", layers: any) =>
  layers.map((layer: any) => ({
    design,
    name: layer.name,
    contents: layer.contents,
    length: layer.length,
    type: layer.type,
    connected: layer.connected,
  }));

const convertJsonToData = (
  design: "front" | "back",
  e: any,
  cb: (newLayerValues: any, colors: any) => void
) => {
  if (e.target.name == "json") {
    const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], "UTF-8");
    fileReader.onload = (e) => {
      if (typeof e.target?.result == "string") {
        const str: string = e.target?.result!;
        const data = JSON.parse(str);
        const colors = getColorsFromJsonData(data.swatches);
        const newLayerValues = getLayerValues(design, data.layers);
        cb?.(newLayerValues, colors);
      }
    };
  }
};

export interface ITemplateAddUpdateModal {
  open: boolean;
  setOpen: Function;
  template: ITemplate;
  handleCallBack: (template: ITemplate, action: string) => void;
}
const defaultErrorValue: ITemplate = {
  altCode: "",
  styleNumber: "",
  name: "",
  style: "",
  subStyle: "",
  design: "",
  frontThumbnail: "",
  frontDocument: "",
  frontDescription: "",
  frontDescriptionVersion: "",
  allFrontDescription: "",
  backThumbnail: "",
  backDocument: "",
  backDescription: "",
  backDescriptionVersion: "",
  allBackDescription: "",
  logos: false,
  b2bWebTitle: "",
  isGraphicLinked: false,
  isGraphic2Linked: false,
};
const designList = ["Front", "Front and Back"];
const designLogoFieldName = [
  { fieldName: "logos", title: "Front" },
  { fieldName: "backLogos", title: "Back" },
];

const designGraphicLink = [
  { fieldName: "isFrontGraphic", title: "Front Graphic I" },
  { fieldName: "isBackGraphic", title: "Back Graphic I" },
];

const designGraphic2Link = [
  { fieldName: "isFrontGraphic2", title: "Front Graphic II" },
  { fieldName: "isBackGraphic2", title: "Back Graphic II" },
];

const TemplateAddUpdateModal: FC<ITemplateAddUpdateModal> = ({
  open,
  setOpen,
  template: defaultTemplate,
  handleCallBack,
}): JSX.Element => {
  const { accessToken } = useAppSelector(getUserDetails);

  const [template, setTemplate] = useState<ITemplate>({ ...defaultTemplate });
  const [errorMsg, setErrorMsg] = useState<ITemplate>({ ...defaultErrorValue });
  const [styleList, setStyleList] = useState<
    { styleName: string; subStyleName: string }[]
  >([]);
  const [blankTemplates, setBlankTemplates] = useState<
    {
      id: number;
      productId: string;
      styleNumber: string;
      description: string;
    }[]
  >([]);
  const [selectedStyle, setSelectedStyle] = useState({
    styleName: "",
    subStyleName: "",
    styleNumber: "",
    b2bWebTitle: "",
  });
  const [frontColors, setFrontColors] = useState<any>([
    { graphicColors: [] },
    { graphicColors2: [] },
    { bodyColors: [] },
  ]);
  const [backColors, setBackColors] = useState<any>([
    { graphicColors: [] },
    { graphicColors2: [] },
    { bodyColors: [] },
  ]);
  const [layers, setLayers] = useState<any>([]);
  const [frontFiles, setFrontFiles] = useState<{
    photo: File | null;
    doc: File | null;
    json: File | null;
  }>({ photo: null, doc: null, json: null });
  const [backFiles, setBackFiles] = useState<{
    photo: File | null;
    doc: File | null;
    json: File | null;
  }>({ photo: null, doc: null, json: null });
  const [loading, setLoading] = useState(false);
  const handleChange = (
    e:
      | ChangeEvent<HTMLSelectElement>
      | ChangeEvent<HTMLInputElement>
      | ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    let errors = { ...errorMsg };
    switch (name) {
      case "name":
        errors.name = value.length < 1 ? `Name can not be empty` : ``;
        break;
      default:
        break;
    }

    setErrorMsg(errors);
    setTemplate((prev) => ({ ...prev, [name]: value }));
  };

  const onChangeFrontFile = (e: any) => {
    try {
      if (e.target.name == "json") {
        checkDescriptionFileName(
          e.target.files[0].name,
          Number(template?.frontDescriptionVersion)
        );
      }
      convertJsonToData("front", e, (newLayerValues, colors) => {
        setLayers([...layers, ...newLayerValues]);
        setFrontColors(colors);
      });
    } catch (err) {
      console.error(err);
      if ((err as string) == "unexpected file name.") {
        toast.error(err as string);
        e.target.value = "";
      }
    }
    setFrontFiles({ ...frontFiles, [e.target.name]: e.target.files[0] });
  };

  const onChangeBackFile = (e: any) => {
    try {
      if (e.target.name == "json") {
        checkDescriptionFileName(
          e.target.files[0].name,
          Number(template.backDescriptionVersion)
        );
      }
      convertJsonToData("back", e, (newLayerValues, colors) => {
        setLayers([...layers, ...newLayerValues]);
        setBackColors(colors);
      });
    } catch (err) {
      console.error(err);
      if ((err as string) == "unexpected file name.") {
        toast.error(err as string);
        e.target.value = "";
      }
    }
    setBackFiles({ ...backFiles, [e.target.name]: e.target.files[0] });
  };

  const validInput = () => {
    if (
      !template.name ||
      !selectedStyle.styleName ||
      !selectedStyle.subStyleName ||
      !template.blankGarmentId ||
      !template.styleNumber
    )
      return false;
    return true;
  };
  const handleSubmit = () => {
    if (!validInput()) {
      alert("Please fill all mandatory fields");
      return;
    }
    let fileList: File[] = [];
    fileList = [
      frontFiles.photo!,
      frontFiles.doc!,
      frontFiles.json!,
      backFiles.photo!,
      backFiles.doc!,
      backFiles.json!,
    ];
    setLoading(true);
    //[file1,null,file2,...] => [link1,"",link2,...]
    uploadFiles(fileList, accessToken).then((uploadUrls) => {
      if (template.id) updateTemplate(uploadUrls);
      else createTemplate(uploadUrls);
    });
    //()=>navigate(URLS.TEMPLATES_DETAIL)
  };
  const updateTemplate = async (uploadUrls: string[]) => {
    const payload: ITemplate & { front: any; back: any; layers: any } = {
      ...template,
      logos: template.logos ? 1 : 0,
      backLogos:
        template.design === designList[1] && template?.backLogos ? 1 : 0,
      style: selectedStyle.styleName,
      subStyle: selectedStyle.subStyleName,
      frontThumbnail: uploadUrls[0] ? uploadUrls[0] : template.frontThumbnail,
      frontDocument: uploadUrls[1] ? uploadUrls[1] : template.frontDocument,
      frontDescription: uploadUrls[2]
        ? uploadUrls[2]
        : template.frontDescription,
      backThumbnail:
        template.design === designList[1]
          ? uploadUrls[3]
            ? uploadUrls[3]
            : template.backThumbnail
          : "",
      backDocument:
        template.design === designList[1]
          ? uploadUrls[4]
            ? uploadUrls[4]
            : template.backDocument
          : "",
      backDescription:
        template.design === designList[1]
          ? uploadUrls[5]
            ? uploadUrls[5]
            : template.backDescription
          : "",
      // TODO: Add more color data on it
      front: {
        graphicColors: frontColors[0].graphicColors
          ? frontColors[0].graphicColors
          : [],
        bodyColors: frontColors[1].bodyColors ? frontColors[1].bodyColors : [],
        graphicColors2:
          frontColors.length > 2 && frontColors[2].graphicColors2
            ? frontColors[2].graphicColors2
            : [],
      },
      back: {
        graphicColors: backColors[0].graphicColors
          ? backColors[0].graphicColors
          : [],
        bodyColors: backColors[1].bodyColors ? backColors[1].bodyColors : [],
        graphicColors2:
          backColors.length > 2 && backColors[2].graphicColors2
            ? backColors[2].graphicColors2
            : [],
      },
      layers: layers,
    };

    try {
      setLoading(true);
      const { data } = await updateTemplateService(
        template.id!,
        payload,
        accessToken
      );
      if (data?.data) {
        alert("Template updated successfully");
        handleCallBack(payload, "update");
        setLoading(false);
        setOpen(false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const addColorSwatches = async (id: string | number) => {
    try {
      const payload: any = {
        templateId: id,
        front: {
          graphicColors: frontColors[0].graphicColors
            ? frontColors[0].graphicColors
            : [],
          bodyColors: frontColors[1].bodyColors
            ? frontColors[1].bodyColors
            : [],
          graphicColors2:
            frontColors.length > 2 && frontColors[2].graphicColors2
              ? frontColors[2].graphicColors2
              : [],
        },
        back: {
          graphicColors: backColors[0].graphicColors
            ? backColors[0].graphicColors
            : [],
          bodyColors: backColors[1].bodyColors ? backColors[1].bodyColors : [],
          graphicColors2:
            backColors.length > 2 && backColors[2].graphicColors2
              ? backColors[2].graphicColors2
              : [],
        },
        layers: layers,
      };
      setLoading(true);
      const { data } = await colorSwatchesAddService(payload, accessToken);

      if (data?.data) {
        setLoading(false);
        return true;
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      return false;
    } finally {
      setLoading(false);
    }
  };
  const createTemplate = async (uploadUrls: string[]) => {
    const payload: ITemplate = {
      ...template,
      logos: template.logos ? 1 : 0,
      backLogos:
        template.design === designList[1] && template?.backLogos ? 1 : 0,
      style: selectedStyle.styleName,
      subStyle: selectedStyle.subStyleName,
      frontThumbnail: uploadUrls[0],
      frontDocument: uploadUrls[1],
      frontDescription: uploadUrls[2],
      backThumbnail: template.design === designList[1] ? uploadUrls[3] : "",
      backDocument: template.design === designList[1] ? uploadUrls[4] : "",
      backDescription: template.design === designList[1] ? uploadUrls[5] : "",
    };
    try {
      setLoading(true);
      const { data } = await createTemplatesService(payload, accessToken);

      if (data?.data) {
        addColorSwatches(data?.data.id).then((res) => {
          if (res === true) {
            toast.success("Template created successfully");
            const temp: ITemplate = data?.data;
            handleCallBack(temp, "add");
            setLoading(false);
            setOpen(false);
          } else {
            toast.warn("Unable to create template. Issue in the json file.");
            setLoading(false);
            deleteTemplatesService(data?.data.id, accessToken);
          }
        });
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const filterByFrontBacks = (
    x: {
      fieldName: string;
      title: string;
    },
    i: number
  ) => {
    if (template.design === designList[1]) {
      return true;
    } else if (i === 0) {
      return true;
    }
    return false;
  };

  const changeLogos = (
    item: string,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTemplate((prev) => ({
      ...prev,
      [item]: e.target.checked,
    }));
  };

  const changeGraphicLink = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTemplate((prev) => ({
      ...prev,
      [e.target.name]: e.target.checked,
    }));
  };

  useEffect(() => {
    const getTemplate = async () => {
      try {
        setLoading(true);

        const { data } = await fetchTemplateDetailsService(
          accessToken,
          template.id!
        );
        if (data.data) {
          console.log({ data });
          setTemplate(data.data);
        }
        setLoading(false);
      } catch (error) {
        console.error(error);
        alert("Could not fetch the template");
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };

    if (template.id) getTemplate();
  }, [accessToken, template.id]);

  useEffect(() => {
    const getStyles = async () => {
      try {
        setLoading(true);
        const { data } = await fetchAllStyleService(accessToken);
        if (data.data) {
          setStyleList(data.data);
        }

        setLoading(false);
      } catch (error) {
        console.error(error);
        alert("Could not fetch the attributes");
        setLoading(false);
      }
    };
    const getBlankTemplates = async () => {
      try {
        setLoading(true);
        const { data } = await fetchLocalBlankGarments(accessToken);
        if (data.data) {
          setBlankTemplates(data.data);
        }

        setLoading(false);
      } catch (error) {
        console.error(error);
        alert("Could not fetch the attributes");
        setLoading(false);
      }
    };
    getStyles();
    getBlankTemplates();
  }, [accessToken]);

  useEffect(() => {
    if (template && template.style)
      setSelectedStyle((prev) => ({
        ...prev,
        styleName: template.style,
        subStyleName: template.subStyle,
      }));
  }, [template]);

  return (
    <Modal
      className="custom-drawer date-picker-modal"
      show={open}
      onHide={() => setOpen(false)}
      aria-labelledby="custom-modal"
      backdrop="static"
      size={"lg"}
    >
      <Modal.Body>
        {loading && <SpinnerLoader />}
        <div className="page-bottom-space">
          <div className="border-bottom pb-1 mb-3">
            <h4 className="mb-0 heading4-bold">
              {template.id ? `Update Template` : `Create Template`}
            </h4>
          </div>

          <Input
            sectionClass="mb-3"
            value={template.name}
            onChange={handleChange}
            label="Template name*"
            name="name"
            placeholder="Abc"
            errorMessage={errorMsg.name}
          />

          <div className="mb-3">
            <p className="mb-2">Style*</p>
            {/* <div className="row">
          {React.Children.toArray(
            styleList.map((style, index) => (
              <div className="col-md-4">
                <div className="custom-checkbox" key={index}>
                  <input
                    className="custom-control-input"
                    type="checkbox"
                    id={`style-${index}`}
                    name={`style-${index}`}
                    value={style.attributeName}
                    onChange={() => handleChangeCheckBox(index)}
                    checked={styleCheckedList[index]}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor={`style-${index}`}
                  >
                    {style.attributeName}
                  </label>
                </div>
              </div>
            ))
          )}
        </div> */}
            <StyleSelectUI
              styleList={styleList}
              selectedStyle={selectedStyle}
              setSelectedStyle={setSelectedStyle}
              template={template}
              setTemplate={setTemplate}
              blankTemplates={blankTemplates}
            />
          </div>

          <div className="mb-3">
            <p className="mb-2">Design</p>
            <div className="row">
              {React.Children.toArray(
                designList.map((el, key) => (
                  <div className="col-md-4" key={key}>
                    <div className="custom-radio">
                      <input
                        className="custom-control-input"
                        type="radio"
                        name="design"
                        id={`design-${el}`}
                        value={el}
                        onChange={handleChange}
                        checked={el === template.design}
                      />
                      <label
                        className="custom-control-label text-capitalize"
                        htmlFor={`design-${el}`}
                      >
                        {el}
                      </label>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>

          <div className="mb-3">
            <p className="mb-2">Graphic Color Link</p>
            <div className="d-flex justify-content-start align-items-center gap-2">
              <input
                type="checkbox"
                id={`graphicLink-isGraphicLinked`}
                name="isGraphicLinked"
                checked={template["isGraphicLinked"]}
                onChange={changeGraphicLink}
              />
              <label htmlFor={`graphicLink-isGraphicLinked`}>
                Link Graphic Color 1
              </label>
            </div>
            <div className="d-flex justify-content-start align-items-center gap-2">
              <input
                type="checkbox"
                id={`graphicLink-isGraphic2Linked`}
                name="isGraphic2Linked"
                checked={template["isGraphic2Linked"]}
                onChange={changeGraphicLink}
              />
              <label htmlFor={`graphicLink-isGraphic2Linked`}>
                Link Graphic Color 2
              </label>
            </div>
          </div>

          <div className="mb-3">
            <p className="mb-2">Logo</p>
            <div>
              {designLogoFieldName.filter(filterByFrontBacks)?.map((item) => (
                <div
                  key={item.fieldName}
                  className="d-flex justify-content-start align-items-center gap-2"
                >
                  <input
                    type="checkbox"
                    id={`logos-${item.fieldName}`}
                    checked={
                      template[
                        item.fieldName === "backLogos" ? "backLogos" : "logos"
                      ]
                        ? true
                        : false
                    }
                    onChange={(e) => changeLogos(item.fieldName, e)}
                  />
                  <label htmlFor={`logos-${item.fieldName}`}>
                    {item.title} logo
                  </label>
                </div>
              ))}
            </div>
          </div>

          <div className="mb-3">
            <p className="mb-2">Front</p>

            {!template.frontThumbnail && (
              <div className="upload__image-wrapper mb-3">
                {!frontFiles.photo && (
                  <button className="btn btn-upload position-relative">
                    <span>
                      <i className="fa-solid fa-cloud-arrow-up heading1"></i>
                      <span className="d-block">
                        Upload Thumbnail (Like jpg file)
                      </span>
                      <input
                        className="hidden-input"
                        accept=".png, .jpg, .jpeg, .svg"
                        placeholder={""}
                        type="file"
                        id="photo-front"
                        name="photo"
                        onChange={onChangeFrontFile}
                      />
                    </span>
                  </button>
                )}
                {frontFiles.photo && (
                  <div className="mt-3">
                    <div className="row g-3">
                      <div className="col-auto">
                        <div className="avatar position-relative overflow-visible">
                          <img
                            className="rounded "
                            src={URL.createObjectURL(frontFiles.photo!)}
                            alt="..."
                          />
                          <button
                            className="btn btn-link-danger btn-x"
                            onClick={() =>
                              setFrontFiles({ ...frontFiles, photo: null })
                            }
                          >
                            <i className="fa-solid fa-xmark"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
            {template.frontThumbnail && (
              <div className="upload__image-wrapper mb-3">
                <div className="mt-3">
                  <div className="row g-3">
                    <div className="col-auto">
                      <div className="avatar position-relative overflow-visible">
                        <img
                          className="rounded "
                          src={template.frontThumbnail}
                          alt="..."
                        />
                        <button
                          className="btn btn-link-danger btn-x"
                          onClick={() =>
                            setTemplate((prev) => ({
                              ...prev,
                              frontThumbnail: "",
                            }))
                          }
                        >
                          <i className="fa-solid fa-xmark"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {!template.frontDocument && (
              <div className="upload__image-wrapper mb-3">
                {!frontFiles.doc && (
                  <button className="btn btn-upload position-relative">
                    <span>
                      <i className="fa-solid fa-cloud-arrow-up heading1"></i>
                      <span className="d-block">
                        Upload Documents (Like Ai file)
                      </span>
                      <input
                        accept=".ai, .pdf"
                        className="hidden-input"
                        type="file"
                        id="doc-front"
                        name="doc"
                        onChange={onChangeFrontFile}
                      />
                    </span>
                  </button>
                )}
                {frontFiles.doc && (
                  <div className="has-right-action d-flex align-items-center mt-3">
                    <p className="mb-0 text-capitalize">
                      {frontFiles.doc?.name}
                    </p>
                    <div className="action-wrap">
                      <button
                        className="btn btn-link-danger ms-3"
                        type="button"
                        onClick={() =>
                          setFrontFiles({ ...frontFiles, doc: null })
                        }
                      >
                        <i className="fa-solid fa-x">{""}</i>
                      </button>
                    </div>
                  </div>
                )}
              </div>
            )}
            {template.frontDocument && (
              <div className="has-right-action d-flex align-items-center mt-3">
                <p className="mb-0 text-capitalize">
                  {template.frontDocument.split("/").pop()}
                </p>
                <div className="action-wrap">
                  <button
                    className="btn btn-link-danger ms-3"
                    type="button"
                    onClick={() =>
                      setTemplate({ ...template, frontDocument: "" })
                    }
                  >
                    <i className="fa-solid fa-x">{""}</i>
                  </button>
                </div>
              </div>
            )}
            {
              <div className="upload__image-wrapper mb-3">
                {
                  <button className="btn btn-upload position-relative">
                    <span>
                      <i className="fa-solid fa-cloud-arrow-up heading1"></i>
                      <span className="d-block">
                        Upload Description (Like json file)
                      </span>
                      <input
                        accept=".json"
                        className="hidden-input"
                        type="file"
                        id="json-front"
                        name="json"
                        onChange={onChangeFrontFile}
                      />
                    </span>
                  </button>
                }
                {frontFiles.json && (
                  <div className="has-right-action d-flex align-items-center mt-3">
                    <p className="mb-0 text-capitalize">
                      {frontFiles.json?.name}
                    </p>
                    <div className="action-wrap">
                      <button
                        className="btn btn-link-danger ms-3"
                        type="button"
                        onClick={() =>
                          setFrontFiles({ ...frontFiles, json: null })
                        }
                      >
                        <i className="fa-solid fa-x">{""}</i>
                      </button>
                    </div>
                  </div>
                )}
              </div>
            }

            {template.allFrontDescription ? (
              <>
                {template.allFrontDescription
                  .split(",")
                  .reverse()
                  .map((fileUrl, key) => (
                    <div
                      className="has-right-action d-flex align-items-center mt-3"
                      key={key}
                    >
                      <p className="mb-0 text-capitalize">
                        <>
                          {fileUrl.split("/").pop()}{" "}
                          {fileUrl.split("/").pop() ==
                            template?.frontDescription.split("/").pop() && (
                            <i className="fa-solid fa-check"></i>
                          )}
                        </>
                      </p>
                      <div className="action-wrap"></div>
                    </div>
                  ))}
              </>
            ) : (
              <>
                {template.frontDescription && (
                  <div className="has-right-action d-flex align-items-center mt-3">
                    <p className="mb-0 text-capitalize">
                      {template.frontDescription.split("/").pop()}
                    </p>
                    <div className="action-wrap"></div>
                  </div>
                )}
              </>
            )}
          </div>

          {template.design === designList[1] && (
            <div className="mb-3">
              <p className="mb-2">Back</p>
              {!template.backThumbnail && (
                <div className="upload__image-wrapper mb-3">
                  {!backFiles.photo && (
                    <button className="btn btn-upload position-relative">
                      <span>
                        <i className="fa-solid fa-cloud-arrow-up heading1"></i>
                        <span className="d-block">
                          Upload Thumbnail (Like jpg file)
                        </span>
                        <input
                          className="hidden-input"
                          accept=".png, .jpg, .jpeg, .svg"
                          type="file"
                          id="photo-back"
                          name="photo"
                          onChange={onChangeBackFile}
                        />
                      </span>
                    </button>
                  )}
                  <div className="mt-3">
                    {backFiles.photo && (
                      <div className="row g-3">
                        <div className="col-auto">
                          <div className="avatar position-relative overflow-visible">
                            <img
                              className="rounded "
                              src={URL.createObjectURL(backFiles.photo!)}
                              alt="..."
                            />
                            <button
                              className="btn btn-link-danger btn-x"
                              onClick={() =>
                                setBackFiles({ ...backFiles, photo: null })
                              }
                            >
                              <i className="fa-solid fa-xmark"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {template.backThumbnail && (
                <div className="upload__image-wrapper mb-3">
                  <div className="mt-3">
                    <div className="row g-3">
                      <div className="col-auto">
                        <div className="avatar position-relative overflow-visible">
                          <img
                            className="rounded "
                            src={template.backThumbnail}
                            alt="..."
                          />
                          <button
                            className="btn btn-link-danger btn-x"
                            onClick={() =>
                              setTemplate((prev) => ({
                                ...prev,
                                backThumbnail: "",
                              }))
                            }
                          >
                            <i className="fa-solid fa-xmark"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {!template.backDocument && (
                <div className="upload__image-wrapper mb-3">
                  {!backFiles.doc && (
                    <button className="btn btn-upload position-relative">
                      <span>
                        <i className="fa-solid fa-cloud-arrow-up heading1"></i>
                        <span className="d-block">
                          Upload Documents (Like Ai file)
                        </span>
                        <input
                          className="hidden-input"
                          accept=".ai, .pdf"
                          type="file"
                          id="doc-back"
                          name="doc"
                          onChange={onChangeBackFile}
                        />
                      </span>
                    </button>
                  )}
                  {backFiles.doc && (
                    <div className="has-right-action d-flex align-items-center mt-3">
                      <p className="mb-0 text-capitalize">
                        {backFiles.doc?.name}
                      </p>
                      <div className="action-wrap">
                        <button
                          className="btn btn-link-danger ms-3"
                          type="button"
                          onClick={() =>
                            setBackFiles({ ...backFiles, doc: null })
                          }
                        >
                          <i className="fa-solid fa-x">{""}</i>
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {template.backDocument && (
                <div className="has-right-action d-flex align-items-center mt-3">
                  <p className="mb-0 text-capitalize">
                    {template.backDocument.split("/").pop()}
                  </p>
                  <div className="action-wrap">
                    <button
                      className="btn btn-link-danger ms-3"
                      type="button"
                      onClick={() =>
                        setTemplate({ ...template, backDocument: "" })
                      }
                    >
                      <i className="fa-solid fa-x">{""}</i>
                    </button>
                  </div>
                </div>
              )}
              {
                <div className="upload__image-wrapper mb-3">
                  {
                    <button className="btn btn-upload position-relative">
                      <span>
                        <i className="fa-solid fa-cloud-arrow-up heading1"></i>
                        <span className="d-block">
                          Upload Description (Like json file)
                        </span>
                        <input
                          accept=".json"
                          className="hidden-input"
                          type="file"
                          id="json-back"
                          name="json"
                          onChange={onChangeBackFile}
                        />
                      </span>
                    </button>
                  }
                  {backFiles.json && (
                    <div className="has-right-action d-flex align-items-center mt-3">
                      <p className="mb-0 text-capitalize">
                        {backFiles.json?.name}
                      </p>
                      <div className="action-wrap">
                        <button
                          className="btn btn-link-danger ms-3"
                          type="button"
                          onClick={() =>
                            setBackFiles({ ...backFiles, json: null })
                          }
                        >
                          <i className="fa-solid fa-x">{""}</i>
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              }
              {template.allBackDescription ? (
                <>
                  {template.allBackDescription
                    .split(",")
                    .reverse()
                    .map((fileUrl, key) => (
                      <div
                        className="has-right-action d-flex align-items-center mt-3"
                        key={key}
                      >
                        <p className="mb-0 text-capitalize">
                          <>
                            {fileUrl.split("/").pop()}{" "}
                            {fileUrl.split("/").pop() ==
                              template?.backDescription.split("/").pop() && (
                              <i className="fa-solid fa-check"></i>
                            )}
                          </>
                        </p>
                        <div className="action-wrap"></div>
                      </div>
                    ))}
                </>
              ) : (
                <>
                  {template.backDescription && (
                    <div className="has-right-action d-flex align-items-center mt-3">
                      <p className="mb-0 text-capitalize">
                        {template.backDescription.split("/").pop()}
                      </p>
                      <div className="action-wrap"></div>
                    </div>
                  )}
                </>
              )}
            </div>
          )}
          <div className="mt-3">
            <button
              type="button"
              className="btn btn-danger px-4"
              onClick={handleSubmit}
            >
              {template.id ? `Update` : `Save`}
            </button>
            <button
              type="button"
              className="btn btn-outline-danger px-4 ms-3"
              onClick={() => {
                handleCallBack(template, "set");
                setOpen(false);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default TemplateAddUpdateModal;
