import * as React from "react";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import { Controller, FormProvider, useForm } from "react-hook-form";

import { FormValues } from "./interface";
import * as inventoryService from "api/inventoryService";
import * as utilService from "api/utilService";
import { DEFAULT_VALUES } from "./helper";
import SpinnerLoader from "ui/loader/SpinnerLoader";

interface IProp {
  blankGarment: any;
  handleClose: () => void;
}

const BlankStyleShippingModel: React.FC<IProp> = (props): JSX.Element => {
  const blankStyleNumber = props.blankGarment.styleNumber;
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const methods = useForm<FormValues>({
    defaultValues: DEFAULT_VALUES,
  });
  const isSubmitting = methods.formState.isSubmitting;

  React.useEffect(() => {
    async function fetchBlankStyleShipping() {
      if (!blankStyleNumber) return;
      setIsLoading(true);
      inventoryService
        .fetchBlankStyleShipping(blankStyleNumber)
        .then((data: any) => {
          const _values: any = {};
          console.log("data", data);
          for (let k in DEFAULT_VALUES) {
            if (data[k]) _values[k] = data[k];
          }
          console.log("values", _values);
          methods.reset(_values);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
    fetchBlankStyleShipping();
  }, [blankStyleNumber]);

  const onSubmit = async (values: FormValues) => {
    if (parseFloat(values.price) < 0) {
      toast.error("Negative input is not valid");
      return;
    }
    try {
      const { ...restValues } = values;
      const payload = {
        ...restValues,
      };

      await inventoryService.updateBlankStyleShipping(
        blankStyleNumber,
        payload
      );
      console.log("payloads", payload);
      props.handleClose();
    } catch (err: any) {
      toast.error(err.message);
    }
  };

  return (
    <>
      {isLoading && <SpinnerLoader></SpinnerLoader>}
      <Modal
        className="custom-drawer date-picker-modal"
        show={true}
        aria-labelledby="custom-modal"
        backdrop="static"
        size={"lg"}
      >
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Modal.Header>
              <div className="row">
                <div className="col-auto">
                  <h2 className="heading4-bold mb-0">
                    Shipping {props.blankGarment.styleNumber}
                  </h2>
                </div>
              </div>
            </Modal.Header>
            <Modal.Body>
              <>
                <div className="overflow-auto" style={{ maxHeight: "600px" }}>
                  <table className="table custom-new-table">
                    <thead>
                      <tr>
                        {/* <th>Shipping</th> */}
                        <th key="price">{"Price"}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        {/* <td></td> */}

                        <td>
                          <Controller
                            control={methods.control}
                            name="price"
                            render={({ field }) => (
                              <input className="form-control" {...field} />
                            )}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </>
            </Modal.Body>
            <Modal.Footer className="justify-content-between">
              <div className="me-3">
                <button
                  type="submit"
                  className="btn btn-danger px-4 me-3"
                  disabled={isSubmitting || isLoading}
                >
                  {isSubmitting ? "Saving..." : "Save"}
                </button>
                <button
                  type="button"
                  className="btn btn-outline-danger px-4"
                  onClick={props.handleClose}
                  disabled={isSubmitting || isLoading}
                >
                  Close
                </button>
              </div>
            </Modal.Footer>
          </form>
        </FormProvider>
      </Modal>
    </>
  );
};

export default BlankStyleShippingModel;
