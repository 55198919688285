const { ENCRIPTING_KEY, ENCRIPTING_IV } = require("../api/apiConstants");

const key = ENCRIPTING_KEY, iv = ENCRIPTING_IV
// Convert a string to an ArrayBuffer
function str2ab(str) {
  return new TextEncoder().encode(str);
}

// Convert an ArrayBuffer to a hex string
function ab2hex(buffer) {
  return [...new Uint8Array(buffer)].map(b => b.toString(16).padStart(2, '0')).join('');
}

// Convert a hex string to an ArrayBuffer
function hex2ab(hex) {
  let result = [];
  for (let i = 0; i < hex.length; i += 2) {
    result.push(parseInt(hex.substr(i, 2), 16));
  }
  return new Uint8Array(result);
}

// AES encryption function using Web Crypto API
export const encryptWithAES = async (text) => {
  const encodedKey = await crypto.subtle.importKey(
    'raw',
    str2ab(key),
    { name: 'AES-CBC' },
    false,
    ['encrypt']
  );

  const encrypted = await crypto.subtle.encrypt(
    { name: 'AES-CBC', iv: str2ab(iv) },
    encodedKey,
    str2ab(text)
  );

  return ab2hex(encrypted); // Convert ArrayBuffer to hex string
}


// AES decryption function using Web Crypto API
export const decryptWithAES = async (encryptedHex) => {
  const encodedKey = await crypto.subtle.importKey(
    'raw',
    str2ab(key),
    { name: 'AES-CBC' },
    false,
    ['decrypt']
  );

  const decrypted = await crypto.subtle.decrypt(
    { name: 'AES-CBC', iv: str2ab(iv) },
    encodedKey,
    hex2ab(encryptedHex) // Convert hex string back to ArrayBuffer
  );

  return new TextDecoder().decode(decrypted);
}