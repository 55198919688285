import { fetchCustomerContactDetailsService } from "api/customerService";
import { ICustomerDetails, IkeyValuePair, ITemplate } from "api/requestTypes";
import {
  downloadTemplateConfigCSVService,
  downloadTemplateConfigLogoCSVService,
  uploadZipFileService,
} from "api/templateService";
import { IMAGES } from "assets/images/images";
import DragDrop from "components/dragDrop/DragDrop";
import CustomTooltip from "components/tooltip/Tooltip";
import { ILocation } from "pages/locations/Locations";
import React, { ChangeEvent, FC, useEffect, useMemo, useState } from "react";
import { useAppSelector } from "store/hooks";
import { getUserDetails } from "store/User.slice";
import SpinnerLoader from "ui/loader/SpinnerLoader";
import Heading from "../../../ui/Heading/Heading";
import TemplateConfigDialog from "../../templates/templateConfigDialog/TemplateConfigDialogV2";
import S3BucketModal from "./S3BucketModal";
import { Dropdown, OverlayTrigger, Popover } from "react-bootstrap";
import PopupImage from "./PopupImage";
import CustomerTemplateGraphicRuleModal from "pages/graphicRule/CustomerTemplateGraphicRuleModal";
import StatusUpdateModal from "./StatusUpdateModal";
import TemplateSelector from "./TemplateSelector";

type TemplateImage = {
  backgroundImage: string;
  graphicImage: string;
  backBackgroundImage: string;
  backGraphicImage: string;
};

type CustomerTemplate = ITemplate & TemplateImage;

type TemplateListProps = {
  setTemplateSelected: React.Dispatch<React.SetStateAction<CustomerTemplate[]>>;
  templateSelected: CustomerTemplate[];
  templateList: ITemplate[];
  setTemplateList: Function;
  templateListAll?: ITemplate[];
  keyListAll?: IkeyValuePair[];
  customInfo?: ICustomerDetails;
  locationList?: ILocation[];
  logoList?: any;
};
const defaultVal: ICustomerDetails = {
  id: "",
  category: "",
  locationId: "",
  royaltyRate: 0,
  logoUrls: "",
  parentId: "",
  createdAt: "",
  updatedAt: "",
  deletedAt: "",
  childData: [],
  customerAccountName: "",
};
const TemplateList: FC<TemplateListProps> = ({
  setTemplateSelected,
  templateSelected,
  templateList,
  setTemplateList,
  templateListAll = [],
  keyListAll = [],
  customInfo = defaultVal,
  locationList = [],
  logoList,
}): JSX.Element => {
  const { accessToken } = useAppSelector(getUserDetails);
  const [selectedTemplateId, setSelectedTemplateId] = useState<string | number>(
    ""
  );
  const [loading, setLoading] = useState(false);
  const [openStatusUpdateModal, setOpenStatusUpdateModal] = useState(false);
  const [checked, setChecked] = useState<{
    allChecked: boolean;
    list: { [key: string]: boolean };
  }>({ allChecked: false, list: {} });
  useEffect(() => {
    setChecked(() => ({
      allChecked: false,
      list: templateSelected.reduce((a, c) => {
        a[c.id as string] = false;
        return a;
      }, {} as { [key: string]: boolean }),
    }));
  }, []);

  const [loadingMessage, setLoadingMessage] = useState("Loading...");
  const [openTemplateConfig, setOpenTemplatesConfig] = useState(false);
  const [isS3bucketModalOpen, setS3BucketModalOpen] = useState(false);
  const [isGraphiListModal, setIsGraphiListModal] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<ITemplate>({
    altCode: "",
    styleNumber: "",
    b2bWebTitle: "",
    id: "",
    name: "",
    logos: false, //true,false => 0,1
    style: "",
    subStyle: "",
    design: "",
    frontThumbnail: "",
    frontDocument: "",
    frontDescription: "",
    frontDescriptionVersion: "",
    allFrontDescription: "",
    backThumbnail: "",
    backDocument: "",
    backDescription: "",
    backDescriptionVersion: "",
    allBackDescription: "",
  });

  const onChangeFile = (e: any) => {
    uploadZipFile(e.target.files[0]);
  };
  const handleTemplateClick = (templateId: number) => {
    const filtered = templateList.filter(
      (obj) => obj.id === templateId
    ) as CustomerTemplate[];
    const remaing = templateList.filter((obj) => obj.id !== templateId);
    console.log(filtered, remaing);
    setTemplateSelected((prevSelected) => [...prevSelected, ...filtered]);
    setTemplateList(remaing);
  };

  const handleLabelClick = (templateMap: any) => {
    const filtered = templateList.filter(
      (obj) => !!templateMap[obj.id as string]
    ) as CustomerTemplate[];
    const remaing = templateList.filter(
      (obj) => !templateMap[obj.id as string]
    );

    setTemplateSelected((prevSelected) => [...prevSelected, ...filtered]);
    setTemplateList(remaing);
  };

  const removeTemplates = (id: string | number) => {
    const removed = templateSelected.filter((obj) => {
      return obj.id === id;
    });
    const result = templateSelected.filter((obj) => {
      return obj.id !== id;
    });
    setTemplateSelected(result);
    setTemplateList([...templateList, ...removed]);
  };
  const updateTemplateStatus = (id: string | number) => {
    // const updatedTemplate = templateSelected.find((obj) => {
    //   return obj.id == id;
    // });
    // const result = templateSelected.filter((obj) => {
    //   return obj.id != id;
    // });
    // setTemplateSelected([{...updatedTemplate,status:'Ready' },...result]);
    const t = templateSelected.map((el) =>
      // el.id === id ? { ...el, status: "Ready" } : el// origin code... cmnted by pps
      el.id === id ? { ...el } : el
    );
    setTemplateSelected(t);
  };
  const updateTemplateImages = (id: number | string, images: TemplateImage) => {
    const t = templateSelected.map((el) =>
      el.id === id
        ? {
            ...el,
            backgroundImage: images.backgroundImage,
            graphicImage: images.graphicImage,
            backBackgroundImage: images.backBackgroundImage,
            backGraphicImage: images.backGraphicImage,
          }
        : el
    );
    setTemplateSelected(t);
  };
  const downloadCSV = async (currentTemplate: ITemplate) => {
    const temp = templateListAll.find((e) => e.id === currentTemplate.id);
    async function dwnldDatabaseCSVFile({
      design,
      isFrBk,
    }: {
      design: "front" | "back";
      isFrBk: boolean;
    }) {
      const payload = {
        childId: customInfo.id,
        templateId: currentTemplate.id,
        design,
      };
      setLoading(true);
      setLoadingMessage("Downloding...please wait");
      const { data } = await downloadTemplateConfigCSVService(
        payload,
        accessToken
      );
      setLoadingMessage("Loading...");
      setLoading(false);
      const csvContent = "data:text/csv;charset=utf-8," + data;
      const encodedUri = encodeURI(csvContent);

      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      let designAbbr = "";
      if (isFrBk) {
        designAbbr = design === "front" ? "-FR" : "-BK";
      }
      const fileName = `${temp?.name}${designAbbr}_${temp?.id}_database`;
      link.setAttribute("download", fileName);
      document.body.appendChild(link); // Required for FF
      link.click();
      link.remove();
    }

    try {
      let isFrBk = false;
      if (temp?.design === "Front and Back") {
        isFrBk = true;
        await dwnldDatabaseCSVFile({ design: "back", isFrBk });
      }
      await dwnldDatabaseCSVFile({ design: "front", isFrBk });
    } catch (error) {
      console.error(error);
      //toast.warn('File unavailable');
    } finally {
      setLoading(false);
      setLoadingMessage("Loading...");
    }
  };
  const downloadCSVForLogos = async (currentTemplate: ITemplate) => {
    const temp = templateListAll.find((e) => e.id === currentTemplate.id);
    if (temp?.logos !== true && temp?.backLogos !== true) return;
    async function dwnldLogosCSVFile({
      design,
      isFrBk,
    }: {
      design: "front" | "back";
      isFrBk: boolean;
    }) {
      const payload = {
        templateId: currentTemplate.id,
        childId: customInfo.id,
        design,
      };
      setLoading(true);
      setLoadingMessage("Downloading...please wait");
      const { data } = await downloadTemplateConfigLogoCSVService(
        payload,
        accessToken
      );
      setLoading(false);
      setLoadingMessage("Loading...");
      const csvContent = "data:text/csv;charset=utf-8," + data;
      const encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      let designAbbr = "";
      if (isFrBk) {
        designAbbr = design === "front" ? "-FR" : "-BK";
      }
      const fileName = `${temp?.name}${designAbbr}_${temp?.id}_logos`;
      link.setAttribute("download", fileName);
      document.body.appendChild(link); // Required for FF
      link.click();
      link.remove();
    }

    try {
      let isFrBk = false;
      if (temp?.design === "Front and Back") {
        isFrBk = true;
        await dwnldLogosCSVFile({ design: "back", isFrBk });
      }
      await dwnldLogosCSVFile({ design: "front", isFrBk });
    } catch (error) {
      console.error(error);
      //toast.warn('File unavailable');
    } finally {
      setLoading(false);
      setLoadingMessage("Loading...");
    }
  };
  const downloadTemplatePdf = async (currentTemplate: ITemplate) => {
    const temp = templateListAll.find((e) => e.id === currentTemplate.id);
    if (temp?.frontDocument) {
      let url = temp?.frontDocument;
      setLoading(true);
      fetch(url).then((response) => {
        setLoadingMessage("Downloading...please wait");
        response.blob().then((blob) => {
          setLoadingMessage("Loading...");
          // Creating new object of PDF file
          const fileURL = window.URL.createObjectURL(blob);
          // Setting various property values
          let alink = document.createElement("a");
          alink.href = fileURL;
          alink.download = `${url.split("/").pop()}`;
          alink.click();
          alink.remove();
        });
      });
      setLoading(false);
    }
    if (temp?.backDocument) {
      let url = temp?.backDocument;
      setLoading(true);
      fetch(url).then((response) => {
        setLoadingMessage("Downloading...please wait");
        response.blob().then((blob) => {
          setLoadingMessage("Loading...");
          // Creating new object of PDF file
          const fileURL = window.URL.createObjectURL(blob);
          // Setting various property values
          let alink = document.createElement("a");
          alink.href = fileURL;
          alink.download = `${url.split("/").pop()}`;
          alink.click();
          alink.remove();
        });
      });
      setLoading(false);
    }
  };
  const downloadFiles = async (currentTemplate: ITemplate) => {
    try {
      downloadCSV(currentTemplate).then(() => {
        downloadCSVForLogos(currentTemplate).then(() => {
          downloadTemplatePdf(currentTemplate);
        });
      });
    } catch (error) {
      console.error();
    }
  };

  const downloadFilesForSelected = () => {
    Object.entries(checked.list).forEach(([id, isChecked]) => {
      if (!isChecked) return;
      const temp = templateSelected.find((temp) => temp.id === Number(id));
      if (!temp) return;
      console.log(temp.name);
      downloadFiles(temp);
    });
  };

  const uploadZipFile = async (zipFile: File) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("file", zipFile);
      formData.append(
        "address",
        `parent/${customInfo.parentId}/customer/${customInfo.id}/template/${selectedTemplateId}`
      );
      const res = await uploadZipFileService(formData, accessToken);

      const data = res?.data?.data;
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const getTemplateList = async () => {
    try {
      setLoading(true);
      const { data } = await fetchCustomerContactDetailsService(
        accessToken,
        customInfo.id
      );

      if (data.data) {
        const { templates } = data.data;

        setTemplateSelected(templates);
        setLoading(false);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
      alert("Could not fetch the templates");
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const closeS3BucketModal = async () => {
    await getTemplateList();
    setS3BucketModalOpen(false);
  };

  const handleCheck = (e: any) => {
    const name = e.target.name;
    const checked = e.target.checked;
    setChecked((prev) => {
      let { list, allChecked } = prev;
      if (name === "check-all") {
        allChecked = checked;
        Object.keys(list).forEach((key) => (list[key] = checked));
      } else {
        list[name] = checked;
        allChecked = Object.keys(list).every((key) => !!list[key]);
      }
      return { allChecked, list };
    });
  };

  const selectedStats = useMemo(() => {
    return {
      total: Object.keys(checked.list).length,
      selected: Object.values(checked.list).reduce(
        (a, c) => (a += Number(!!c)),
        0
      ),
    };
  }, [checked]);

  if (loading) return <SpinnerLoader message={loadingMessage} />;
  // return <SpinnerLoader message={"Downloading...."}/>
  const __url =
    "https://stdrec.s3.amazonaws.com/assets/templates/33/Background/ack%20red%20-%2018-1629%20tcx_lt.png";

  return (
    <div className="mb-3">
      <TemplateSelector
        templateList={templateList}
        handleTemplateClick={handleTemplateClick}
        handleLabelClick={handleLabelClick}
      />
      {/* for last item removing the border of the list just add "no-border" class on the li of the iterable array */}
      <ul
        className="list-group list-group-flush list-style-none pb-3 pt-3 mb-1"
        style={{ borderBottom: "1px solid rgba(96, 97, 100, 0.4)" }}
      >
        <li className="list-item-mb-2">
          <div className="has-right-action">
            <div className="action-wrap d-flex align-items-center">
              <div className="custom-checkbox grey-shade me-1 ms-2">
                <input
                  className="custom-control-input"
                  type="checkbox"
                  id="check-all"
                  name="check-all"
                  onChange={handleCheck}
                  checked={checked.allChecked}
                />
                <label className="custom-control-label" htmlFor="sd"></label>
              </div>
              <span
                className="text-secondary ms-2"
                style={{ minWidth: "52px", textAlign: "center" }}
              >
                ({selectedStats.selected}/{selectedStats.total})
              </span>
              <CustomTooltip msg={"Update S3 Bucket Status"}>
                <button
                  type="button"
                  className="btn btn-link text-secondary position-relative btn-img ms-2 overflow-hidden"
                  disabled={!selectedStats.selected}
                  onClick={() => setOpenStatusUpdateModal(true)}
                >
                  <img
                    className="img-fluid"
                    src={IMAGES.UploadCloud}
                    alt="upload"
                  />
                </button>
              </CustomTooltip>
              <CustomTooltip msg={"Download"}>
                <button
                  className="btn btn-link text-secondary position-relative btn-img ms-2"
                  onClick={downloadFilesForSelected}
                  disabled={!selectedStats.selected}
                >
                  <img
                    className="img-fluid"
                    src={IMAGES.DownloadCloud}
                    alt="download"
                  />
                </button>
              </CustomTooltip>
              <CustomTooltip msg={"Graphics Rules List"}>
                <button
                  type="button"
                  className="btn btn-link-danger bg-white text-white pointer-none ms-2"
                >
                  <i className="fa-solid fa-palette"></i>
                </button>
              </CustomTooltip>
              <CustomTooltip msg={"Edit Config"}>
                <button
                  className="btn btn-link-danger  bg-white text-white pointer-none ms-2"
                  type="button"
                >
                  <i className="fa-solid fa-pen">{""}</i>
                </button>
              </CustomTooltip>
              <CustomTooltip msg={"Remove form template"}>
                <button
                  className="btn btn-link-danger ms-2 text-white pointer-none"
                  type="button"
                >
                  <i className="fa-solid fa-xmark">{""}</i>
                </button>
              </CustomTooltip>
            </div>
          </div>
        </li>
      </ul>

      {templateSelected.length > 0 && (
        <ul className="list-group list-group-flush has-border-bottom list-style-none mb-2">
          <DragDrop<CustomerTemplate>
            list={templateSelected}
            setList={setTemplateSelected}
          >
            {(temp) => (
              <li className="list-item-mb-2" key={`temp-${temp.id}`}>
                <div className="has-right-action">
                  <p className="mb-0 text-capitalize">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Popover style={{ height: "50%", width: "100%" }}>
                          <PopupImage
                            design={temp.design}
                            backgroundImageUrl={temp.backgroundImage}
                            graphicImageUrl={temp.graphicImage}
                            backBackgroundImageUrl={temp.backBackgroundImage}
                            backGraphicImageUrl={temp.backGraphicImage}
                          />
                        </Popover>
                      }
                    >
                      <span>{`${temp.name} (ID-${temp.id})`}</span>
                    </OverlayTrigger>
                  </p>

                  <div className="action-wrap d-flex align-items-center">
                    <div className="custom-checkbox grey-shade me-1">
                      <input
                        className="custom-control-input"
                        type="checkbox"
                        id={temp.id as string}
                        name={temp.id as string}
                        value={temp.id}
                        onChange={handleCheck}
                        checked={checked.list[temp.id as string]}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor={temp.id as string}
                      ></label>
                    </div>
                    <span
                      className="badge badge-rounded bg-secondary text-white ms-2"
                      style={{ minWidth: "52px" }}
                    >
                      {temp.status ? temp.status : ""}
                    </span>
                    <CustomTooltip msg={"Update S3 Bucket Status"}>
                      <button
                        type="button"
                        onClick={() => {
                          setS3BucketModalOpen(true);
                          setSelectedTemplate(temp);
                        }}
                        className="btn btn-link text-secondary position-relative btn-img ms-2 overflow-hidden"
                        disabled={temp.status ? false : true}
                      >
                        <img
                          className="img-fluid"
                          src={IMAGES.UploadCloud}
                          alt="upload"
                        />
                      </button>
                    </CustomTooltip>
                    <CustomTooltip msg={"Download"}>
                      <button
                        className="btn btn-link text-secondary position-relative btn-img ms-2"
                        onClick={() => {
                          downloadFiles(temp);
                        }}
                        // disabled={temp.status === 'Ready' ? false : true}
                      >
                        <img
                          className="img-fluid"
                          src={IMAGES.DownloadCloud}
                          alt="download"
                        />
                      </button>
                    </CustomTooltip>
                    <CustomTooltip msg={"Graphics Rules List"}>
                      <button
                        type="button"
                        onClick={() => {
                          setIsGraphiListModal(true);
                          setSelectedTemplate(temp);
                        }}
                        className="btn btn-link-danger ms-2"
                      >
                        <i className="fa-solid fa-palette"></i>
                      </button>
                    </CustomTooltip>
                    <CustomTooltip msg={"Edit Config"}>
                      <button
                        className="btn btn-link-danger ms-2"
                        type="button"
                        onClick={() => {
                          setSelectedTemplateId(temp.id!);
                          setOpenTemplatesConfig(true);
                        }}
                        disabled={temp.status ? false : true}
                      >
                        <i className="fa-solid fa-pen">{""}</i>
                      </button>
                    </CustomTooltip>
                    <CustomTooltip msg={"Remove form template"}>
                      <button
                        className="btn btn-link-danger ms-2"
                        type="button"
                        onClick={() => removeTemplates(temp.id!)}
                      >
                        <i className="fa-solid fa-xmark">{""}</i>
                      </button>
                    </CustomTooltip>
                  </div>
                </div>
              </li>
            )}
          </DragDrop>
        </ul>
      )}
      {openTemplateConfig && (
        <TemplateConfigDialog
          open={openTemplateConfig}
          setOpen={setOpenTemplatesConfig}
          templateListAll={templateListAll}
          keyListAll={keyListAll}
          customInfo={customInfo}
          locationList={locationList}
          currentTemplateId={selectedTemplateId}
          logos={logoList}
          updateTemplateStatus={updateTemplateStatus}
          updateTemplateImages={updateTemplateImages}
        />
      )}

      {isS3bucketModalOpen && (
        <S3BucketModal
          open={isS3bucketModalOpen}
          customInfo={customInfo}
          onClose={closeS3BucketModal}
          template={selectedTemplate}
        />
      )}

      {isGraphiListModal && (
        <CustomerTemplateGraphicRuleModal
          template={selectedTemplate}
          customInfo={customInfo}
          show={isGraphiListModal}
          handleSuccess={() => {
            setIsGraphiListModal(false);
          }}
          onHide={() => setIsGraphiListModal(false)}
        />
      )}
      {openStatusUpdateModal && (
        <StatusUpdateModal
          open={openStatusUpdateModal}
          onClose={() => setOpenStatusUpdateModal(false)}
          accessToken={accessToken}
          childId={customInfo.id}
          selection={checked}
          setTemplateSelected={setTemplateSelected}
        />
      )}
    </div>
  );
};

export default TemplateList;
